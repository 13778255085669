import { useEffect, useRef, useState } from 'react';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import {
  CHART_BG_COLOR,
  GRID_LINE_COLOR,
  INITIAL_SELECT_RANGE_VALUE,
} from '@/views/EntityMonitoringView/components/SyncCharts/utils/constants';
import { adjustRiskZoneChartLabelForAlertStatusTrending } from '@/helpers/riskZoneMethods';
import {
  alertStatusTooltipFormatter,
  flowsTooltipFormatter,
  syncChartsTickPositioner,
} from '@/views/EntityMonitoringView/components/SyncCharts/utils/helpers';
import { useSyncChartsDatasetsManager } from '@/views/EntityMonitoringView/components/SyncCharts/utils/useSyncChartsDatasetsManager';
import { useAnomalies } from '@/views/EntityMonitoringView/context/AnomaliesProvider.hooks';
import { ISyncChartsProps } from '@/views/EntityMonitoringView/components/SyncCharts/SyncCharts';

export const useSyncChartsManager = (props: Omit<ISyncChartsProps, 'balances'>) => {
  const flowsChartRef = useRef<HighchartsReactRefObject | null>(null);
  const alertStatusChartRef = useRef<HighchartsReactRefObject | null>(null);
  const [syncing, setSyncing] = useState<boolean>(false);

  const rangeRef = useRef<number | null>(null);
  const [selectedRange, setSelectedRange] = useState(INITIAL_SELECT_RANGE_VALUE);

  const handleRangeChange = (range: number) => {
    setSelectedRange(range);
  };

  const { areSyncTimestampsAvailable, alertStatusChart, flowsChart } = useSyncChartsDatasetsManager(props);
  const { handleScrollAndTransactionsVisibility } = useAnomalies();

  const [flowsChartOptions, setFlowsChartOptions] = useState({
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: CHART_BG_COLOR,
      zoomType: 'x',
      marginLeft: 120,
    },
    title: {
      text: 'Flows',
      style: {
        color: '#F7F7F7',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22.584px',
      },
      align: 'right',
    },
    rangeSelector: {
      enabled: true,
      selected: selectedRange,
      buttons: [
        {
          type: 'day',
          count: 1,
          text: '1d',
          events: { click: () => handleRangeChange(0) },
        },
        {
          type: 'day',
          count: 2,
          text: '2d',
          events: { click: () => handleRangeChange(1) },
        },
        {
          type: 'day',
          count: 7,
          text: '7d',
          events: { click: () => handleRangeChange(2) },
        },
        {
          type: 'month',
          count: 1,
          text: '1M',
          events: { click: () => handleRangeChange(3) },
        },
        {
          type: 'all',
          text: 'All',
          events: { click: () => handleRangeChange(4) },
        },
      ],
    },
    scrollbar: {
      enabled: true,
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      borderWidth: 0,
      itemStyle: {
        color: '#FFFFFF80',
      },
      itemHoverStyle: {
        color: '#FFFFFF',
      },
    },
    xAxis: {
      crosshair: true,
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineColor: GRID_LINE_COLOR,
      type: 'datetime',
      tickLength: 0,
      tickPositioner: syncChartsTickPositioner,
      showLastLabel: true,
      labels: {
        format: '{value:%e %b}',
        style: {
          color: '#F7F7F7',
          fontSize: '14px',
        },
      },
      events: {
        afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
          if (syncing) return;
          setSyncing(true);
          if (alertStatusChartRef.current && alertStatusChartRef.current.chart) {
            alertStatusChartRef.current.chart.xAxis[0].setExtremes(event.min, event.max, true, false);
          }
          setSyncing(false);
        },
      },
    },
    yAxis: {
      opposite: false,
      gridLineColor: GRID_LINE_COLOR,
      title: {
        text: undefined,
      },
      tickInterval: null,
      endOnTick: true,
      startOnTick: true,
      labels: {
        format: '${text}',
        reserveSpace: true,
        style: {
          color: '#F7F7F7',
          lineHeight: '15.809px',
        },
      },
    },
    tooltip: {
      shared: true,
      split: false,
      crosshairs: true,
      useHTML: true,
      formatter: flowsTooltipFormatter,
    },
    plotOptions: {
      column: {
        grouping: false,
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0.1,
      },
    },
    series: [
      {
        type: 'column',
        name: 'Inflows',
        data: flowsChart.inflowData,
        pointPlacement: 0,
        color: 'green',
        dataGrouping: {
          enabled: true,
          approximation: 'sum',
        },
      },
      {
        type: 'column',
        name: 'Outflows',
        data: flowsChart.outflowData,
        pointPlacement: 0,
        color: 'red',
        dataGrouping: {
          enabled: true,
          approximation: 'sum',
        },
      },
      {
        type: 'line',
        name: 'Netflows',
        data: flowsChart.netflowData,
        lineWidth: 2,
        color: '#FFA500',
        dataGrouping: {
          enabled: true,
          approximation: 'sum',
        },
      },
    ],
    time: {
      useUTC: false,
    },
    ...DISABLE_CHART_EXPORTING_MENU,
  });

  const [alertStatusChartOptions, setAlertStatusChartOptions] = useState({
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: CHART_BG_COLOR,
      type: 'line',
      zoomType: 'x',
      marginLeft: 120,
      height: 320,
    },
    title: {
      text: 'Alert Status',
      style: {
        color: '#F7F7F7',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22.584px',
      },
      align: 'right',
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: true,
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      borderWidth: 0,
      itemStyle: {
        color: '#FFFFFF80',
      },
      itemHoverStyle: {
        color: '#FFFFFF',
      },
    },
    xAxis: {
      crosshair: true,
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineColor: GRID_LINE_COLOR,
      type: 'datetime',
      tickLength: 0,
      tickPositioner: syncChartsTickPositioner,
      showLastLabel: true,
      labels: {
        format: '{value:%e %b}',
        style: {
          color: '#F7F7F7',
          fontSize: '14px',
        },
      },
      events: {
        afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
          if (syncing) return;
          setSyncing(true);
          if (flowsChartRef.current && flowsChartRef.current.chart) {
            flowsChartRef.current.chart.xAxis[0].setExtremes(event.min, event.max, true, false);
          }
          setSyncing(false);
        },
      },
    },
    yAxis: {
      opposite: false,
      gridLineColor: GRID_LINE_COLOR,
      title: {
        text: undefined,
      },
      min: 0,
      max: 5,
      tickInterval: 1,
      tickAmount: 6,
      labels: {
        reserveSpace: true,
        formatter: function (this: { value: number }) {
          return adjustRiskZoneChartLabelForAlertStatusTrending(this.value).toUpperCase();
        },
        style: {
          color: '#F7F7F7',
          lineHeight: '15.809px',
        },
      },
    },
    tooltip: {
      shared: true,
      split: false,
      crosshairs: true,
      useHTML: true,
      pointFormatter: alertStatusTooltipFormatter,
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: true,
          approximation: 'high', // Use max value instead of average
        },
      },
      line: {
        cursor: 'default',
        marker: {
          enabled: true,
          radius: 1,
        },
        states: {
          hover: {
            lineWidth: 2,
          },
        },
      },
      scatter: {
        cursor: 'pointer',
      },
    },
    series: [
      {
        type: 'line',
        name: 'Alert Status',
        data: alertStatusChart.alertStatusData,
        lineWidth: 2,
        marker: {
          symbol: 'square',
        },
      },
      {
        type: 'line',
        name: 'Netflows Severity',
        data: alertStatusChart.netflowData,
        color: '#00E272',
        visible: false,
        lineWidth: 2,
        marker: {
          symbol: 'diamond',
        },
      },
      {
        type: 'scatter',
        name: 'Alerts',
        data: alertStatusChart.anomalyData,
        color: '#E54545',
        marker: {
          symbol: 'circle',
        },
        point: {
          events: {
            click: function () {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const clickedAnomalyId = this.anomaly.id;

              if (!clickedAnomalyId) return;

              handleScrollAndTransactionsVisibility(clickedAnomalyId);
            },
          },
        },
      },
    ],
    time: {
      useUTC: false,
    },
    ...DISABLE_CHART_EXPORTING_MENU,
  });

  // Manage select range of the charts
  useEffect(() => {
    rangeRef.current = selectedRange;
  }, [selectedRange]);

  useEffect(() => {
    setFlowsChartOptions((prev) => ({
      ...prev,
      rangeSelector: {
        ...prev.rangeSelector,
        selected: rangeRef.current ?? INITIAL_SELECT_RANGE_VALUE,
      },
    }));
  }, []);

  // Sync charts zoom
  useEffect(() => {
    if (
      alertStatusChartRef.current &&
      alertStatusChartRef.current.chart &&
      flowsChartRef.current &&
      flowsChartRef.current.chart
    ) {
      const extremes = flowsChartRef.current.chart.xAxis[0].getExtremes();
      alertStatusChartRef.current.chart.xAxis[0].setExtremes(extremes.min, extremes.max, true, false);
    }
  }, []);

  return {
    areSyncTimestampsAvailable,
    chartOptions: { flowsChartOptions, alertStatusChartOptions },
    refs: {
      flowsChartRef,
      alertStatusChartRef,
    },
  };
};
