import { ApiEndpoints } from '@/types/routes';
import { IChangelogApi } from '@/api/changelog/changelog.types';

export class ChangelogApi implements IChangelogApi {
  private getBaseUrl(): string {
    return ApiEndpoints.CHANGELOG;
  }

  async getChangelog() {
    const response = await fetch(this.getBaseUrl());

    if (!response.ok) {
      throw new Error('Failed to load changelog');
    }

    return response.text();
  }
}
