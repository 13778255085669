import { Typography } from '@mui/material';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { DefaultRisks } from '@/views/EntityMonitoringView/components/EntityDetails/components/DefaultRisks/DefaultRisks';
import { ShortTermRisk } from '@/views/EntityMonitoringView/components/EntityDetails/components/ShortTermRisk/ShortTermRisk';
import { SignificantData } from '@/views/EntityMonitoringView/components/EntityDetails/components/SignificantData/SignificantData';
import {
  StyledColumnFlexWrapper,
  StyledEntityWrapper,
  StyledGridSectionContainer,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { TSeverity } from '@/types/severityLevel';
import { TRiskZone } from '@/types/riskZone';
import { IFlowsDataset, IObservableBalance, IPdDataset } from '@/api/monitoring/monitoring.types';

export interface IEntityDetailsProps {
  riskZone?: TRiskZone;
  riskZoneLastUpdateTimestamp?: string | null;
  displayName: string | null;
  isRated: boolean;
  pd: IPdDataset | null;
  proofOfReservesUrl: string | null;
  balance: IObservableBalance | null;
  flows: IFlowsDataset | null;
  tag: string | null;
  adjustedBalance: TSeverity;
  // riskZones: IRiskZoneDTO[];
}

export const EntityDetails = ({
  displayName,
  isRated,
  balance,
  flows,
  pd,
  proofOfReservesUrl,
  riskZone,
  riskZoneLastUpdateTimestamp,
  tag,
  adjustedBalance,
}: // riskZones,
IEntityDetailsProps) => {
  const { getExchangeLabelByDisplayName } = useEntitiesList();
  const entityName = displayName ? getExchangeLabelByDisplayName(displayName) : '';

  // const uniqueFilteredRiskZones = useMemo(() => {
  //   if (!riskZones.length) return [];
  //
  //   return riskZones.filter((riskZone, i, all) => {
  //     const timestamp = riskZone.timestamp;
  //     const allPickedByDate = all.filter((one) => one.timestamp === timestamp);
  //
  //     if (allPickedByDate.length === 1) return true;
  //
  //     const mapAllPickedByDate = allPickedByDate.map((item) => adjustRiskZoneToNumber(item.value));
  //
  //     const pickMax = maxBy(mapAllPickedByDate, (status) => status ?? -Infinity);
  //
  //     const pickedMaxStatus = isNumber(pickMax) ? adjustRiskZoneNumberToKey(pickMax) : null;
  //
  //     const indexOfFoundRiskValue = all.findIndex(
  //       (one) => one.timestamp === timestamp && one.value === pickedMaxStatus,
  //     );
  //
  //     return i === indexOfFoundRiskValue;
  //   });
  // }, [riskZones]);
  //
  // const frequencyDataPoints: IFrequencyDataPoint[] = useMemo(() => {
  //   const sortOrder: RiskZoneKey[] = ['critical', 'high', 'moderate', 'no_risk'];
  //   //Make sure that nullable values of the risk zones will be filtered out to make sure that they will be treated as a "data unavailable"
  //   const nonNullableRiskZones = uniqueFilteredRiskZones.filter((riskZone) => riskZone.value !== null);
  //
  //   const alertStatusCounts = countBy(nonNullableRiskZones, 'value');
  //
  //   const result: IFrequencyDataPoint[] = (Object.keys(alertStatusCounts) as RiskZoneKey[]).map((name) => ({
  //     name,
  //     y: alertStatusCounts[name],
  //     color: adjustRiskZoneColorFromValue(name),
  //   }));
  //
  //   const sortedResult = sortBy(result, (item) => sortOrder.indexOf(item.name));
  //
  //   const totalNumberOfFetchedStatuses = nonNullableRiskZones.length;
  //   const totalNumberOfStatuses = 90 * 24; // 90 days - hourly
  //   const dataUnavailableStatusNumber = totalNumberOfStatuses - totalNumberOfFetchedStatuses;
  //
  //   return [
  //     ...sortedResult,
  //     ...(dataUnavailableStatusNumber
  //       ? [
  //           {
  //             name: 'no_data' as RiskZoneKey,
  //             y: dataUnavailableStatusNumber,
  //             color: adjustRiskZoneColorFromValue('no_data'),
  //           },
  //         ]
  //       : []),
  //   ];
  // }, [uniqueFilteredRiskZones]);

  return (
    <>
      <StyledEntityWrapper>
        <ExchangeLogo companyName={entityName} />
        <Typography color="white.100" fontSize="24px">
          {displayName}
        </Typography>
      </StyledEntityWrapper>

      <StyledGridSectionContainer>
        <StyledColumnFlexWrapper gap="16px">
          <ShortTermRisk
            riskZone={riskZone}
            riskZoneLastUpdateTimestamp={riskZoneLastUpdateTimestamp}
            tag={tag}
            adjustedBalance={adjustedBalance}
            // frequencyDataPoints={frequencyDataPoints}
          />
        </StyledColumnFlexWrapper>

        <StyledColumnFlexWrapper gap="16px">
          <SignificantData balance={balance} flows={flows} proofOfReservesUrl={proofOfReservesUrl} />
        </StyledColumnFlexWrapper>

        <StyledColumnFlexWrapper gap="16px">
          <DefaultRisks isRated={isRated} displayName={displayName} pd={pd} />
        </StyledColumnFlexWrapper>
      </StyledGridSectionContainer>
    </>
  );
};
