import { Typography } from '@mui/material';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { RiskZoneLabel } from '@/components/RiskZoneLabel/RiskZoneLabel';
import { SeverityLabel } from '@/components/SeverityLabel/SeverityLabel';
import { ParameterWithTimer } from '@/views/EntityMonitoringView/components/EntityDetails/components/ParameterWithTimer/ParameterWithTimer';
// import { AlertStatusFrequencyChart } from '@/views/EntityMonitoringView/components/EntityDetails/components/ShortTermRisk/AlertStatusFrequencyChart';
import {
  StyledSpacedBetweenRowWrapper,
  StyledSubSectionTitle,
  StyledColumnFlexWrapper,
  StyledInternalWrapper,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';
// import { IFrequencyDataPoint } from '@/types/chartPoint';

const NETFLOWS_TOOLTIP_DESCRIPTION =
  'Analyzes net balance trends to detects anomalous outflows relative to the cumulative netflows and the overall size of the entity’s observable balances.';

const ALERT_STATUS_TOOLTIP_DESCRIPTION = (
  <>
    <p>
      Alert Status represents a combined status derived from the severity levels of Netflows and Totalflows. This metric
      determines when alerts are issued to end users, providing a high-level overview of current activity.
      <br />
      There are 4 levels:
      <ul style={{ margin: 0 }}>
        <li>Baseline,</li>
        <li>Monitored,</li>
        <li>Heightened,</li>
        <li>Elevated.</li>
      </ul>
    </p>
  </>
);

// const STATUS_FREQUENCY_TOOLTIP_DESCRIPTION = "Distribution of the entity's alert status recorded in the past 90 days.";

// interface IShortTermRiskProps
//   extends Pick<
//     IEntityDetailsProps,
//     'riskZone' | 'riskZoneLastUpdateTimestamp' | 'tag' | 'onChainActivity' | 'adjustedBalance'
//   > {
//   frequencyDataPoints: IFrequencyDataPoint[];
// }

export const ShortTermRisk = ({
  riskZone,
  riskZoneLastUpdateTimestamp,
  tag,
  adjustedBalance,
}: // frequencyDataPoints,
Pick<IEntityDetailsProps, 'riskZone' | 'riskZoneLastUpdateTimestamp' | 'tag' | 'adjustedBalance'>) => {
  return (
    <>
      <StyledSubSectionTitle>Monitoring</StyledSubSectionTitle>

      <StyledColumnFlexWrapper>
        <StyledSpacedBetweenRowWrapper>
          <ParameterWithTimer
            parameter="Status"
            timestamp={riskZoneLastUpdateTimestamp ?? undefined}
            shouldDisplayTooltip
            tooltipDescription={ALERT_STATUS_TOOLTIP_DESCRIPTION}
          />
          <RiskZoneLabel riskZone={riskZone} fontSize={FONT_SIZE} />
        </StyledSpacedBetweenRowWrapper>

        <Typography color="#c4c4c4" fontSize="16px" align="right">
          {tag}
        </Typography>
      </StyledColumnFlexWrapper>

      <StyledInternalWrapper>
        <StyledSpacedBetweenRowWrapper>
          <ParameterWithTimer
            parameter="Netflows"
            shouldDisplayTooltip
            tooltipDescription={NETFLOWS_TOOLTIP_DESCRIPTION}
          />
          <SeverityLabel severity={adjustedBalance} fontSize={FONT_SIZE} />
        </StyledSpacedBetweenRowWrapper>

        {/*<StyledSpacedBetweenRowWrapper>*/}
        {/*  <ParameterWithTimer*/}
        {/*    parameter="Status Frequency Last 90 days"*/}
        {/*    shouldDisplayTooltip*/}
        {/*    tooltipDescription={STATUS_FREQUENCY_TOOLTIP_DESCRIPTION}*/}
        {/*  />*/}
        {/*  <AlertStatusFrequencyChart data={frequencyDataPoints} />*/}
        {/*</StyledSpacedBetweenRowWrapper>*/}
      </StyledInternalWrapper>
    </>
  );
};
