import { useCallback } from 'react';
import { toPairs } from 'lodash';
import { Typography } from '@mui/material';
import { formatDateWithTime } from '@/helpers/helpers';
import { isArrayOfStrings } from '@/helpers/typeGuards';
import {
  StyledItem,
  StyledList,
  StyledWrapper,
} from '@/views/AdminPanelView/components/Entities/AdminPanelEntityEventLog/components/EventLogItem/EventLogItem.styled';
import { IEntityEventLog } from '@/types/eventLog';

interface IEventLogItemProps {
  eventLog: IEntityEventLog;
}

export const EventLogItem = ({ eventLog }: IEventLogItemProps) => {
  const { timestamp, event, data } = eventLog;
  const formattedDate = formatDateWithTime(timestamp);

  const generateList = useCallback(() => {
    return toPairs(data).map(([key, value]) => {
      let valueToDisplay = `Something went wrong while reading a value, please contact Justyna.`;

      if (value === null) {
        valueToDisplay = 'null';
      }

      if (typeof value === 'string') {
        valueToDisplay = `"${value}"`;
      }

      if (isArrayOfStrings(value)) {
        valueToDisplay = `"${value.join('; ')}"`;
      }

      if (typeof value === 'boolean') {
        valueToDisplay = `"${value.toString()}"`;
      }

      return (
        <StyledItem key={key}>
          <Typography fontWeight="bold"> {key}:</Typography>
          <Typography fontStyle="italic">{valueToDisplay}</Typography>
        </StyledItem>
      );
    });
  }, [data]);

  return (
    <StyledWrapper>
      <Typography color="white.100" fontWeight="bold">
        {event.toUpperCase()} - {formattedDate}
      </Typography>
      <StyledList>{generateList()}</StyledList>
    </StyledWrapper>
  );
};
