import { useEffect, useRef } from 'react';
import { AnomaliesProvider } from '@/views/EntityMonitoringView/context/AnomaliesProvider';
import { EntityDetails } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';
import { AnomaliesTable } from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable';
import { SyncCharts } from '@/views/EntityMonitoringView/components/SyncCharts/SyncCharts';
import {
  StyledContainer,
  StyledTextWrapper,
  StyledWrapper,
} from '@/views/EntityMonitoringView/EntityMonitoringView.styled';
import { IGetMonitoringDetailsResponse } from '@/api/monitoring/monitoring.types';

interface IEntityMonitoringViewProps {
  fetchedData: IGetMonitoringDetailsResponse;
}

export const EntityMonitoringView = ({ fetchedData }: IEntityMonitoringViewProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    displayName,
    analystNotes,
    isRated,
    balance,
    pd,
    proofOfReservesUrl,
    flows,
    severity,
    riskZones,
    importantAnomalies,
    balances,
    hourlyInflowData,
    hourlyOutflowData,
  } = fetchedData;

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'auto', inline: 'center' });
  }, []);

  return (
    <StyledContainer ref={scrollRef}>
      <StyledWrapper>
        <EntityDetails
          displayName={displayName}
          isRated={!!isRated}
          balance={balance}
          flows={flows}
          pd={pd}
          proofOfReservesUrl={proofOfReservesUrl}
          riskZone={riskZones[0]?.value}
          riskZoneLastUpdateTimestamp={riskZones[0]?.lastUpdated}
          tag={severity.description}
          adjustedBalance={severity.netflow}
          // riskZones={riskZones}
        />

        {!!analystNotes && <StyledTextWrapper dangerouslySetInnerHTML={{ __html: analystNotes }} />}

        <AnomaliesProvider latestAnomalies={importantAnomalies}>
          <SyncCharts
            balances={balances}
            riskZones={riskZones}
            hourlyInflowData={hourlyInflowData}
            hourlyOutflowData={hourlyOutflowData}
          />

          <AnomaliesTable />
        </AnomaliesProvider>
      </StyledWrapper>
    </StyledContainer>
  );
};
