import { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AnomaliesContext } from '@/views/EntityMonitoringView/context/AnomaliesProvider';
import { assertIsDefined } from '@/helpers/assertions';
import { compareDesc, parseISO } from 'date-fns';
import { uniqBy } from 'lodash';
import { IAnomalyShortForMonitoringDto } from '@/types/anomaly';
import { AnomalyDetailsVisibility } from '@/views/EntityMonitoringView/context/AnomaliesProvider.types';

const PAGE_HEADER_HEIGHT = 99;

export const useAnomaliesManager = (latestAnomalies: IAnomalyShortForMonitoringDto[]) => {
  const clickedAnomalyIdRef = useRef<string | null>(null);
  const anomaliesSectionRef = useRef<HTMLDivElement | null>(null);
  const anomaliesTableContainerRef = useRef<HTMLDivElement | null>(null);
  const anomaliesTableStickyHeaderRef = useRef<HTMLTableSectionElement | null>(null);

  const uniqueSortedAnomalies = useMemo(() => {
    const filteredTotalflowsAlerts = latestAnomalies.filter((anomaly) => anomaly.metadata.type !== 'totalflow');

    const sortedAnomalies = [...filteredTotalflowsAlerts].sort((a, b) => {
      return compareDesc(parseISO(a.timestamp), parseISO(b.timestamp));
    });

    return uniqBy(sortedAnomalies, 'id');
  }, [latestAnomalies]);

  const getInitialStateDetailsVisibility = (anomalies: IAnomalyShortForMonitoringDto[]) => {
    return anomalies.map((anomaly) => {
      return {
        id: anomaly.id,
        isDisplayed: false,
        timestamp: anomaly.timestamp.split('T')[0],
      };
    });
  };

  const [anomaliesTransactionDetailsVisibility, setAnomaliesTransactionDetailsVisibility] = useState<
    AnomalyDetailsVisibility[]
  >(() => getInitialStateDetailsVisibility(uniqueSortedAnomalies));

  const toggleAnomalyDetailsVisibility = (anomalyId: string) => {
    setAnomaliesTransactionDetailsVisibility((prev) => {
      return prev.map((prevAnomaly) => {
        return prevAnomaly.id === anomalyId
          ? {
              ...prevAnomaly,
              isDisplayed: !prevAnomaly.isDisplayed,
            }
          : prevAnomaly;
      });
    });
  };

  const handleScrollAndTransactionsVisibility = (anomalyId: string, shouldDisplayAllAnomaliesByDate = false) => {
    clickedAnomalyIdRef.current = anomalyId;

    setAnomaliesTransactionDetailsVisibility((prev) => {
      const clickedAnomaly = prev.find((anomaly) => anomaly.id === anomalyId);
      if (!clickedAnomaly) return prev;

      return prev.map((prevAnomaly) => {
        const manageDisplaySetup = () => {
          if (!shouldDisplayAllAnomaliesByDate) return prevAnomaly.id === anomalyId;

          return prevAnomaly.id === anomalyId || prevAnomaly.timestamp === clickedAnomaly.timestamp;
        };
        return {
          ...prevAnomaly,
          isDisplayed: manageDisplaySetup(),
        };
      });
    });
  };

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (!clickedAnomalyIdRef.current) return;

      const doesClickedAnomalyExist = anomaliesTransactionDetailsVisibility.find(
        (anomaly) => anomaly.id === clickedAnomalyIdRef.current,
      );

      if (
        doesClickedAnomalyExist &&
        anomaliesSectionRef.current &&
        anomaliesTableContainerRef.current &&
        anomaliesTableStickyHeaderRef.current
      ) {
        const anomaliesSection = anomaliesSectionRef.current;
        const tableContainer = anomaliesTableContainerRef.current;
        const anomaliesTableRowId = document.getElementById(clickedAnomalyIdRef.current);

        //Scroll to the anomalies section
        const anomaliesSectionOffsetPosition =
          anomaliesSection.getBoundingClientRect().top + window.scrollY - PAGE_HEADER_HEIGHT;

        window.scrollTo({ top: anomaliesSectionOffsetPosition, behavior: 'smooth' });

        //Scroll to the clicked anomaly within the table
        if (anomaliesTableRowId) {
          const stickyHeader = anomaliesTableStickyHeaderRef.current;

          const headerHeight = stickyHeader?.offsetHeight || 0;
          const containerRect = tableContainer.getBoundingClientRect();
          const anomalyRect = anomaliesTableRowId.getBoundingClientRect();

          const offsetWithinTable = anomalyRect.top - containerRect.top;
          const offsetPosition = tableContainer.scrollTop + offsetWithinTable - headerHeight;

          tableContainer.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }

        // Reset clickedAnomalyIdRef
        clickedAnomalyIdRef.current = null;
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [
    clickedAnomalyIdRef.current,
    anomaliesSectionRef.current,
    anomaliesTableContainerRef.current,
    anomaliesTableStickyHeaderRef.current,
    anomaliesTransactionDetailsVisibility,
  ]);

  return {
    uniqueSortedAnomalies,
    anomaliesTransactionDetailsVisibility,
    toggleAnomalyDetailsVisibility,
    handleScrollAndTransactionsVisibility,
    anomaliesSectionRef,
    anomaliesTableContainerRef,
    anomaliesTableStickyHeaderRef,
  };
};

export const useAnomalies = () => {
  const {
    uniqueSortedAnomalies,
    anomaliesTransactionDetailsVisibility,
    toggleAnomalyDetailsVisibility,
    handleScrollAndTransactionsVisibility,
    anomaliesSectionRef,
    anomaliesTableContainerRef,
    anomaliesTableStickyHeaderRef,
  } = useContext(AnomaliesContext);

  assertIsDefined(uniqueSortedAnomalies, '"IAnomaliesContext.uniqueSortedAnomalies has to be defined!"');
  assertIsDefined(
    anomaliesTransactionDetailsVisibility,
    '"IAnomaliesContext.anomaliesTransactionDetailsVisibility has to be defined!"',
  );
  assertIsDefined(
    toggleAnomalyDetailsVisibility,
    '"IAnomaliesContext.toggleAnomalyDetailsVisibility has to be defined!"',
  );
  assertIsDefined(
    handleScrollAndTransactionsVisibility,
    '"IAnomaliesContext.handleScrollAndTransactionsVisibility has to be defined!"',
  );
  assertIsDefined(anomaliesSectionRef, '"IAnomaliesContext.anomaliesSectionRef has to be defined!"');
  assertIsDefined(anomaliesTableContainerRef, '"IAnomaliesContext.anomaliesTableContainerRef has to be defined!"');
  assertIsDefined(
    anomaliesTableStickyHeaderRef,
    '"IAnomaliesContext.anomaliesTableStickyHeaderRef has to be defined!"',
  );

  return {
    uniqueSortedAnomalies,
    anomaliesTransactionDetailsVisibility,
    toggleAnomalyDetailsVisibility,
    handleScrollAndTransactionsVisibility,
    anomaliesSectionRef,
    anomaliesTableContainerRef,
    anomaliesTableStickyHeaderRef,
  };
};
