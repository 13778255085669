import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { StyledIconWrapper } from './IconWithHover.styled';
import { Link } from '@mui/material';

interface CommonProps {
  variant: 'link' | 'button' | 'menu';
  Icon: React.ElementType;
  tooltip: string;
}

interface LinkVariant {
  variant: 'link';
  onClick?: never;
  onMouseOver?: never;
  onMouseLeave?: never;
  href: string;
  id?: never;
  ariaControls?: never;
  ariaExpanded?: never;
}

interface ButtonVariant {
  variant: 'button';
  onClick: () => void;
  onMouseOver?: never;
  onMouseLeave?: never;
  href?: never;
  id?: never;
  ariaControls?: never;
  ariaExpanded?: never;
}

interface MenuVariant {
  variant: 'menu';
  onClick?: never;
  onMouseOver: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave: () => void;
  href?: never;
  id: string;
  ariaControls?: string;
  ariaExpanded?: 'true';
}

type TIconWithHoverProps = CommonProps & (LinkVariant | ButtonVariant | MenuVariant);

export const IconWithHover = ({
  variant,
  Icon,
  onClick,
  onMouseOver,
  onMouseLeave,
  href,
  tooltip,
  ariaControls,
  ariaExpanded,
}: TIconWithHoverProps) => {
  if (variant === 'link') {
    return (
      <Tooltip title={tooltip}>
        <Link href={href}>
          <StyledIconWrapper>
            <Icon />
          </StyledIconWrapper>
        </Link>
      </Tooltip>
    );
  }

  if (variant === 'button') {
    return (
      <Tooltip title={tooltip}>
        <StyledIconWrapper onClick={onClick}>
          <Icon />
        </StyledIconWrapper>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip}>
      <StyledIconWrapper
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        aria-controls={ariaControls}
        aria-haspopup="true"
        aria-expanded={ariaExpanded}
      >
        <Icon />
      </StyledIconWrapper>
    </Tooltip>
  );
};
