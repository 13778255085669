export const colors = {
  bg: {
    primary: '#101014',
    secondary: '#1E1E1E',
  },
  whiteNew: {
    pure: '#FFFFFF',
    normal: '#E8E8E8',
  },
  black: '#000000',
  grey: {
    deeperDark: '#1B1B1E',
    deepDark: '#282829',
    dark: '#2E2E31',
    medium: '#88888A',
    light: '#C4C4C4',
  },
  purple: {
    medium: '#5D51B8',
    light: '#9388E8',
  },
  red: {
    deepDark: '#2B1E1E',
    dark: '#C00000',
    medium: '#E62137',
    bright: '#F60102',
  },
  yellow: {
    dark: '#F4B400',
    bright: '#FEFF01',
  },
  orange: '#FAC002',
  green: {
    deepDark: '#242A24',
    medium: '#4EA72E',
    light: '#21E67B',
  },
};
