import { Typography } from '@mui/material';
import RedirectIcon from '@/assets/icons/redirect-icon.svg?react';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { formatLargeDollarValueWithSuffix } from '@/helpers/helpers';
import { ParameterWithTimer } from '@/views/EntityMonitoringView/components/EntityDetails/components/ParameterWithTimer/ParameterWithTimer';
import { FlowsSection } from '@/views/EntityMonitoringView/components/EntityDetails/components/SignificantData/FlowsSection';
import {
  StyledLink,
  StyledSpacedBetweenRowWrapper,
  StyledSubSectionTitle,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

const LATEST_OBSERVABLE_BALANCE_TOOLTIP_DESCRIPTION = (
  <>
    <p>
      Observable Balance represents the on-chain balances tracked using our continuously evolving wallet monitoring
      methodology.
    </p>
    <p>While we strive to capture the most comprehensive view possible, we cannot guarantee 100% wallet coverage.</p>
  </>
);

export const SignificantData = ({
  balance,
  flows,
  proofOfReservesUrl,
}: Pick<IEntityDetailsProps, 'balance' | 'flows' | 'proofOfReservesUrl'>) => {
  return (
    <>
      <StyledSubSectionTitle>On-Chain Data</StyledSubSectionTitle>

      {balance && (
        <StyledSpacedBetweenRowWrapper>
          <ParameterWithTimer
            parameter="Latest Observable Balance"
            shouldDisplayTooltip
            tooltipDescription={LATEST_OBSERVABLE_BALANCE_TOOLTIP_DESCRIPTION}
            timestamp={balance.lastUpdated}
          />
          <Typography color="white.100" fontSize={FONT_SIZE} align="right">
            {formatLargeDollarValueWithSuffix(balance.value)}
          </Typography>
        </StyledSpacedBetweenRowWrapper>
      )}

      {flows !== null && (
        <FlowsSection
          inflow={flows.inflow}
          outflow={flows.outflow}
          netflow={flows.netflow}
          lastUpdated={flows.lastUpdated}
        />
      )}

      {!!proofOfReservesUrl && (
        <StyledLink href={proofOfReservesUrl} underline="none" target="_blank" rel="noopener noreferrer">
          <Typography color="#c4c4c4" fontSize={FONT_SIZE} display="flex" alignItems="center" gap="3px" noWrap>
            Proof of Reserves <RedirectIcon />
          </Typography>
        </StyledLink>
      )}
    </>
  );
};
