import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Navigate, useParams } from 'react-router-dom';
import { ERoutes } from '@/types/routes';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import Loader from '@/components/atoms/Loader/Loader';

export const StyledLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

export const EntityMonitoringRoute = ({ children }: PropsWithChildren) => {
  const { exchanges, isEntitiesListLoading, isEntitiesListError } = useEntitiesList();
  const { entityName } = useParams();

  if (isEntitiesListLoading) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  if (isEntitiesListError) {
    throw isEntitiesListError;
  }

  if (!isEntitiesListLoading && exchanges.length > 0 && !!entityName) {
    const exchangeValues = exchanges.map((exchange) => exchange.value);
    const entityNameExists = exchangeValues.includes(entityName);

    if (entityNameExists) {
      return <>{children}</>;
    }

    return <Navigate to={ERoutes.MONITORING} replace />;
  }

  return null;
};
