import { IRiskZone } from '@/types/riskZone';

export const riskZones: IRiskZone[] = [
  { name: 'Baseline', value: 'no_risk' },
  { name: 'Monitored', value: 'moderate' },
  { name: 'Heightened', value: 'high' },
  { name: 'Elevated', value: 'critical' },
  { name: 'Data unavailable', value: 'no_data' },
];

export const RISK_ZONES = ['critical', 'high', 'moderate', 'no_risk', 'no_data'] as const;
