import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  margin: 0 auto;
  width: 800px;

  color: #fff;
`;

export const StyledLoaderContainer = styled.div`
  margin: 20px;
`;
