import styled from '@emotion/styled';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';

export const StyledEntityDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 1300px;
`;

export const StyledAdminButton = styled(AdminButton)`
  box-sizing: border-box;
  width: 253px;
  align-self: flex-end;
  font-size: 14px;
  text-align: center;
`;
