import { createContext, PropsWithChildren } from 'react';
import { useEntitiesListManager } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { IEntitiesListContext } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';

export const EntitiesListContext = createContext<Partial<IEntitiesListContext>>({});

export const EntitiesListProvider = ({ children }: PropsWithChildren) => {
  const {
    exchanges,
    isEntitiesListLoading,
    isEntitiesListError,
    entitiesManagedByTypes,
    getExchangeLabelByDisplayName,
    getDisplayNameByExchangeLabel,
    exchangesDisplayedToAdmin,
  } = useEntitiesListManager();

  const VALUE: IEntitiesListContext = {
    exchanges,
    isEntitiesListLoading,
    isEntitiesListError,
    entitiesManagedByTypes,
    getExchangeLabelByDisplayName,
    getDisplayNameByExchangeLabel,
    exchangesDisplayedToAdmin,
  };

  return <EntitiesListContext.Provider value={VALUE}>{children}</EntitiesListContext.Provider>;
};
