import { isNull } from 'lodash';
import { Typography } from '@mui/material';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { formatFloatToPercent } from '@/helpers/helpers';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { PdChangeComponent } from '@/components/PdChangeComponent/PdChangeComponent';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const PdInfo = ({ pd }: Pick<IEntityDetailsProps, 'pd'>) => {
  const { shouldDisplayRisks } = usePermissionsManager();

  if (!shouldDisplayRisks) {
    return (
      <Typography color="white.100" fontSize={FONT_SIZE} style={{ filter: 'blur(5px)' }} align="right">
        XX.XX%
      </Typography>
    );
  }

  if (!pd) {
    return (
      <Typography color="white.100" fontSize={FONT_SIZE} align="right">
        Data unavailable
      </Typography>
    );
  }

  const pdYearToDisplay = !isNull(pd.pdYear) ? formatFloatToPercent(pd.pdYear) : 'Data unavailable';

  return (
    <>
      <Typography color="white.100" fontSize={FONT_SIZE} align="right">
        {pdYearToDisplay}
      </Typography>
      {pd.pdChange && (
        <>
          <Typography fontSize={FONT_SIZE} align="right">
            <PdChangeComponent specificPdChange={pd.pdChange} />
          </Typography>
          <Typography color="#c4c4c4" fontSize="12px" fontWeight="300" align="right">
            (7d change)
          </Typography>
        </>
      )}
    </>
  );
};
