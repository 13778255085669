import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { AppBar, Toolbar, Typography } from '@mui/material';
import ArrowLeft from '@/assets/icons/arrow-left.svg?react';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import Button from '@/components/atoms/Button/Button';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const EntityRatingsHeader = () => {
  const { entityName } = useParams();
  const navigate = useNavigate();

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ overflow: 'auto', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => navigate(-1)}
            skipTypography
            disableRipple
            blockHoverBehavior
            sx={{ paddingInline: '5px', width: 'auto' }}
          >
            <ArrowLeft />
          </Button>
          <ExchangeLogo companyName={entityName || ''} height="70px" />
          <Typography variant="h4" style={{ flexGrow: 1 }} noWrap>
            {getFullExchangeName(entityName || '')}
          </Typography>
        </div>
      </Toolbar>
    </StyledHeader>
  );
};

export default EntityRatingsHeader;
