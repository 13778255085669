import { subDays, subMonths, format, differenceInCalendarDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { round } from 'lodash';

export const getTimeRangeStartDate = (timeRange: string) => {
  const now = new Date();

  switch (timeRange) {
    case 'lastDay':
      return format(subDays(now, 1), "yyyy-MM-dd'T'HH:mm:ss");
    case 'last15Days':
      return format(subDays(now, 15), "yyyy-MM-dd'T'HH:mm:ss");
    case 'last30Days':
      return format(subDays(now, 30), "yyyy-MM-dd'T'HH:mm:ss");
    case 'lastQuarter':
      return format(subMonths(now, 3), "yyyy-MM-dd'T'HH:mm:ss");
    default:
      return '';
  }
};

export const formatEpochToDate = (epoch: number): string => {
  const date = new Date(epoch * 1000);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
  return formattedDate;
};

export const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(-2);

  return `${month}/${day}/${year}`;
};

export const formatDateWithTime = (dateStr: string) => {
  const date = new Date(dateStr);
  return format(date, 'dd MMM yyyy, HH:mm:ss');
};

export const formatToUSD = (amount: number, noDecimals?: boolean) => {
  const noDecimalsOptions = noDecimals ? { minimumFractionDigits: 0, maximumFractionDigits: 0 } : {};
  const formattedAmount = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...noDecimalsOptions,
  }).format(amount);

  return formattedAmount;
};

export const formatNumberToEnUS = (amount: number) => {
  const formattedAmount = amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return formattedAmount;
};

export const amountToNumber = (amount: string | undefined) => {
  if (!amount) return NaN;

  const number = Number(amount.replace(/[^0-9.-]+/g, ''));
  return number;
};

export const formatDateToUK = (timestamp: Date | string) => {
  try {
    const date = new Date(timestamp);
    return format(date, 'dd/MM/yy');
  } catch (error) {
    console.error('Wrong date:' + timestamp);
    console.error(error);
    return '';
  }
};

export const formatDateToAlert = (timestamp: Date | string) => {
  try {
    const date = new Date(timestamp);
    return format(date, 'LLLL d, yyyy');
  } catch (error) {
    console.error('Wrong date:' + timestamp);
    console.error(error);
    return '';
  }
};

export const formatFloatToPercent = (value: number): string => {
  if (value === null || value === undefined) return '';

  return value.toFixed(2) + '%';
};

export const getListOfNumbers = (length: number, startFromZero = true): number[] =>
  Array.from({ length }, (_, index) => (startFromZero ? index : index + 1));

export const sortAlphabetically = (items: string[]) => {
  return items.sort((a, b) => {
    const nameA = a.toLowerCase();
    const nameB = b.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

const getMinutesDifference = (targetDate: Date, currentDate: Date) => {
  const minutesDifferenceNumber = differenceInMinutes(currentDate, targetDate);

  if (minutesDifferenceNumber === 1) {
    return `${minutesDifferenceNumber} minute ago`;
  }
  return `${minutesDifferenceNumber} minutes ago`;
};

const getHoursDifference = (targetDate: Date, currentDate: Date, minutesVariant = true) => {
  const hoursDifferenceNumber = differenceInHours(currentDate, targetDate);

  if (minutesVariant && hoursDifferenceNumber === 0) {
    return getMinutesDifference(targetDate, currentDate);
  }

  if (hoursDifferenceNumber === 1) {
    return `${hoursDifferenceNumber} hour ago`;
  }

  return `${hoursDifferenceNumber} hours ago`;
};

const getCalendarDaysDifference = (targetDate: Date, currentDate: Date, hoursVariant = true, minutesVariant = true) => {
  const daysDifferenceNumber = differenceInCalendarDays(currentDate, targetDate);

  if (hoursVariant && daysDifferenceNumber === 0) {
    return getHoursDifference(targetDate, currentDate, minutesVariant);
  }

  if (daysDifferenceNumber === 1) {
    return `${daysDifferenceNumber} day ago`;
  }

  return `${daysDifferenceNumber} days ago`;
};

export const getTimestampDifferenceFromNow = (target: string, hoursVariant = true, minutesVariant = true) => {
  //format timestamp to type Date with no changed value (timestamp from BE is already in UTC)
  const targetDate = new Date(
    Date.UTC(
      parseInt(target.slice(0, 4)), //Year
      parseInt(target.slice(5, 7)) - 1, // Month (JS counts from 0)
      parseInt(target.slice(8, 10)), // Days
      parseInt(target.slice(11, 13)), // Hours
      parseInt(target.slice(14, 16)), // Munutes
      parseInt(target.slice(17, 19)), // Seconds
    ),
  );

  //get currentDate of UTC time
  const now = new Date();
  const currentDate = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
    ),
  );

  //compare both dates in UTC
  return getCalendarDaysDifference(targetDate, currentDate, hoursVariant, minutesVariant);
};

export const capitalizeFirstLetters = (text: string) => {
  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const formatLargeDollarValueWithSuffix = (value: number, precision = 2) => {
  if (value >= 1_000_000_000) {
    const roundedValue = round(value / 1_000_000_000, precision);
    return `$${roundedValue.toLocaleString('en-US')}B`;
  }

  if (value >= 1_000_000) {
    return `$${round(value / 1_000_000, precision)}M`;
  }

  if (value >= 1_000) {
    return `$${round(value / 1_000, precision)}K`;
  }

  if (value <= -1_000_000_000) {
    const roundedValue = round((-1 * value) / 1_000_000_000, precision);
    return `-$${roundedValue.toLocaleString('en-US')}B`;
  }

  if (value <= -1_000_000) {
    return `-$${round((-1 * value) / 1_000_000, precision)}M`;
  }

  if (value <= -1_000) {
    return `-$${round((-1 * value) / 1_000, precision)}K`;
  }

  if (value < 0) {
    return `-$${round(-1 * value, precision)}`;
  }

  if (value === 0) {
    return `$${round(value, precision)}`;
  }

  return `$${round(value, precision).toString()}`;
};

export const formatNumberWithSuffix = (value: number, precision = 2) => {
  const formatter = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: precision });

  return formatter.format(value);
};
