import styled from '@emotion/styled';

export const StyledSeverityWrapper = styled.div<{ color?: string; fontSize?: string }>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: fit-content;
  color: ${({ color }) => color};
  text-transform: capitalize;
  font-size: ${({ fontSize }) => fontSize};
  white-space: nowrap;
`;
