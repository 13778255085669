import styled from '@emotion/styled';
import { Link, Table, TableCell, TableHead, TableRow } from '@mui/material';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 34px;
  border: 1px solid #2e2e31;
`;

export const StyledSectionTitle = styled.span`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  opacity: 0.5;
  text-transform: uppercase;
`;

export const StyledLink = styled(Link)`
  cursor: pointer;

  p {
    transition: color 0.3s;
  }

  &:hover p {
    color: #5967e3;
  }
`;

export const StyledNoRows = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
`;

export const StyledTable = styled(Table)`
  min-width: 700px;
  position: relative;
`;

export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  left: 0;
  background-color: #101014;
  z-index: 1;
`;

export const StyledHeaderTableRow = styled(TableRow)`
  &:last-child td,
  &:last-child th {
    border-bottom: 1px solid #2e2e31;
  }
`;

export const StyledTableRow = styled(TableRow)<{ isHoverDeactivated?: boolean }>`
  transition: background 0.3s;

  td,
  th {
    border-bottom: 1px solid #2e2e31;
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }

  &:hover {
    background: ${({ isHoverDeactivated }) => !isHoverDeactivated && '#222'};
  }
`;

export const StyledHeaderCell = styled(TableCell)`
  color: #ffffff80;
  white-space: nowrap;
`;

export const StyledNestedTableCell = styled(TableCell)`
  padding-bottom: 0;
  padding-top: 0;
`;

export const StyledCell = styled(TableCell)`
  color: #ffffff;
`;

export const StyledTableWrapper = styled.div`
  overflow: auto;
  height: 500px;
`;
