import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useGetEntityEventLog } from '@/api/adminEntityIds/adminEntityIds.hooks';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import Loader from '@/components/atoms/Loader/Loader';
import Text from '@/components/atoms/Text/Text';
import { LogList } from '@/views/AdminPanelView/components/Entities/AdminPanelEntityEventLog/components/LogList';
import { StyledContainer } from '@/views/AdminPanelView/components/Entities/AdminPanelEntityEventLog/AdminPanelEntityEventLog.styled';

export const AdminPanelEntityEventLog = () => {
  const { id } = useParams<{ id: string }>();
  const { fetchedEntityEventLog, isLoading, error, isSuccess } = useGetEntityEventLog(id || '');
  const { exchangesDisplayedToAdmin } = useEntitiesList();

  if (isLoading) {
    return <Loader />;
  }

  if (error) return <Typography color="white.100">Error getting entity event log: {error.message}</Typography>;

  if (!fetchedEntityEventLog || (isSuccess && fetchedEntityEventLog && !fetchedEntityEventLog.length))
    return <Typography color="white.100">There is no history to display.</Typography>;

  const entityName = exchangesDisplayedToAdmin.find((entity) => entity.id === id);

  return (
    <StyledContainer>
      <Text fontSize="20px">{entityName?.label} Event Log</Text>
      <LogList eventLogList={fetchedEntityEventLog} />
    </StyledContainer>
  );
};
