import { PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useRatings } from '@/providers/RatingsListContextProvider/RatingsListContext.hooks';
import Loader from '@/components/atoms/Loader/Loader';
import { ERoutes } from '@/types/routes';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const EntityRatingsRoute = ({ children }: PropsWithChildren) => {
  const { entitiesList, isLoadingRatings, ratingsError } = useRatings();
  const { entityName } = useParams();

  if (ratingsError) return <Navigate to={ERoutes.RATINGS} replace />;

  if (!isLoadingRatings && !!entitiesList && entityName) {
    const entityNameExists = entitiesList.includes(entityName);

    if (entityNameExists) {
      return <>{children}</>;
    }

    return <Navigate to={ERoutes.RATINGS} replace />;
  }

  return (
    <StyledContainer>
      <Loader />
    </StyledContainer>
  );
};
