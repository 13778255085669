import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IDirectionColouredTextProps } from '@components/DirectionColouredText/DirectionColouredText';

const neutralColor = css`
  color: #808080;
`;

export const StyledDirectionColouredText = styled.span<IDirectionColouredTextProps>`
  color: ${({ isPositiveDirection }) => (isPositiveDirection ? '#007f1a' : '#ff0b17')};

  ${({ isNeutral }) => isNeutral && neutralColor};
`;
