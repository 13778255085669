import styled from '@emotion/styled';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';
import { ENTITIES_CONTAINER_PADDING } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/EntitiesMonitoringForm.styled';
import Button from '@/components/atoms/Button/Button';

const CONTAINER_PADDING_TOP = '22px';
const CONTAINER_PADDING_RIGHT = '24px';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: ${CONTAINER_PADDING_TOP} ${CONTAINER_PADDING_RIGHT};
  width: 100%;
`;

export const StyledForm = styled.form`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledButtonContainer = styled.div`
  position: fixed;
  top: calc(${HEADER_HEIGHT} + ${CONTAINER_PADDING_TOP} + ${ENTITIES_CONTAINER_PADDING});
  right: calc(${CONTAINER_PADDING_RIGHT} + ${ENTITIES_CONTAINER_PADDING});
`;

export const StyledButton = styled(Button)<{ isDesktopScreen: boolean }>`
  min-width: ${({ isDesktopScreen }) => isDesktopScreen && '142px'};
  width: ${({ isDesktopScreen }) => !isDesktopScreen && '50px'};
  height: ${({ isDesktopScreen }) => !isDesktopScreen && '50px'};
  border-radius: ${({ isDesktopScreen }) => !isDesktopScreen && '50%'};
`;
