import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { getTimestampDifferenceFromNow } from '@/helpers/helpers';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { StyledInfoIcon, StyledTitleWrapper, StyledWrapper, StyledIconWrapper } from './ParameterWithTimer.styled';

interface IParameterWithTimerProps {
  shouldDisplayTooltip?: boolean;
  tooltipDescription?: ReactNode;
  parameter: string;
  timestamp?: string;
}

export const ParameterWithTimer = ({
  shouldDisplayTooltip = false,
  tooltipDescription,
  parameter,
  timestamp = '',
}: IParameterWithTimerProps) => {
  const timer = getTimestampDifferenceFromNow(timestamp);
  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        {shouldDisplayTooltip && (
          <Tooltip arrow TransitionComponent={Zoom} placement="left" title={tooltipDescription}>
            <StyledIconWrapper>
              <StyledInfoIcon />
            </StyledIconWrapper>
          </Tooltip>
        )}

        <Typography color="#c4c4c4" fontSize={FONT_SIZE}>
          {parameter}
        </Typography>
      </StyledTitleWrapper>

      {timestamp && (
        <Typography color="#c4c4c4" fontSize="12px" fontWeight="300">
          Updated: {timer}
        </Typography>
      )}
    </StyledWrapper>
  );
};
