import posthog from 'posthog-js';

const trackUsers = import.meta.env.PROD;

if (trackUsers) {
  posthog.init(import.meta.env.VITE_POSTHOG_PROJECTID, { api_host: 'https://us.i.posthog.com' });
}

function getPageName(pathname: string) {
  if (pathname === '/ratings') {
    return 'Ratings - list';
  }
  if (/^\/ratings\/([a-zA-Z0-9_]+)$/.test(pathname)) {
    return 'Ratings - details';
  }
  if (/^\/monitoring\/([a-zA-Z0-9_]+)$/.test(pathname)) {
    return `Monitoring - dashboard`;
  }
  if (pathname === '/monitoring/settings') {
    return 'Monitoring - settings';
  }
  if (/^\/monitoring\/entity\/([a-zA-Z0-9_]+)$/.test(pathname)) {
    return 'Monitoring - entity details';
  }
  if (pathname === '/risk-simulator') {
    return 'Risk Simulator';
  }
  if (pathname === '/risk-simulator/portfolio') {
    return 'Risk Simulator - Portfolio';
  }
  if (pathname === '/documentation/changelog') {
    return 'Documentation - Changelog';
  }
  if (pathname === '/settings') {
    return 'Settings';
  }

  console.error(`Unknown page: ${pathname}`);
  return pathname;
}

export const useTracking = () => {
  const resetTracking = () => {
    if (trackUsers) {
      posthog.capture('User Logout', {
        Time: new Date().toISOString(),
      });
      posthog.reset();
    }
  };

  const identifyUser = (user: Record<string, any>) => {
    if (trackUsers) {
      const { id, ...rest } = user;
      posthog.identify(id);
      posthog.setPersonProperties(rest);
    }
  };

  const trackEvent = (eventName: string, eventProperties: Record<string, any> = {}) => {
    if (trackUsers) {
      posthog.capture(eventName, eventProperties);
    }
  };

  const trackLogin = (userEmail: string, userPermissions: string) => {
    if (trackUsers) {
      posthog.capture('User Login Info', {
        Time: new Date().toISOString(),
        UserEmail: userEmail,
        Permissions: userPermissions,
      });
    }
  };

  const trackPageView = (pathname: string) => {
    const pageName = getPageName(pathname);

    if (trackUsers) {
      posthog.capture(`Page Viewed: ${pageName}`, {
        page: pathname,
      });
    }
    if (posthog) {
      posthog.capture('$pageview', {
        $current_url: pathname,
      });
    }
  };

  const trackFilterUsage = (filterType: string, filterValue: string | string[]) => {
    if (import.meta.env.PROD) {
      posthog.capture(`Filter Applied: ${filterType}`, {
        'Filter Type': filterType,
        'Filter Value': filterValue,
      });
    }
  };

  return { trackLogin, trackPageView, trackFilterUsage, identifyUser, resetTracking, trackEvent };
};
