import styled from '@emotion/styled';
import { Link, Typography } from '@mui/material';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';

export const StyledGridSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px;
  padding-bottom: 5px;
  overflow: auto;
`;

export const StyledSubSectionTitle = styled(Typography)`
  padding-block: 12px;
  border-bottom: 1px solid #c4c4c4;
  color: #ffffff;
  font-size: ${FONT_SIZE};
`;

export const StyledSpacedBetweenRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
`;

export const StyledEntityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 36px;
`;

export const StyledColumnFlexWrapper = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;

export const StyledInternalWrapper = styled(StyledColumnFlexWrapper)`
  gap: 16px;
  padding-left: 24px;
`;

export const StyledLink = styled(Link)`
  width: fit-content;

  p {
    transition: color 0.3s;
  }

  &:hover p {
    color: #5967e3;
  }
`;
