import { formatInTimeZone } from 'date-fns-tz';
import { Box, Table, TableBody, TableHead, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import InfoIcon from '@/assets/icons/info-bold.svg?react';
import { isChainKey } from '@/helpers/typeGuards';
import { adjustAddressHref, adjustTrxHashHref } from '@/helpers/chainMethods';
import { capitalizeFirstLetters, formatLargeDollarValueWithSuffix, formatNumberWithSuffix } from '@/helpers/helpers';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import {
  StyledCell,
  StyledHeaderCell,
  StyledHeaderTableRow,
  StyledLink,
  StyledTableRow,
} from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable.styled';
import { ICounterPartyData } from '@/types/anomaly';
import { IAlertMetadata } from '@/types/alert';
import { TSeverity } from '@/types/severityLevel';

interface IAnomalyTransactionsTableProps {
  row: {
    id: string;
    timestamp: string;
    counterParties: ICounterPartyData[];
    severity: TSeverity;
    metadata: Pick<IAlertMetadata, 'type'>;
  };
}

const LARGEST_5_OUTFLOWS_DESCRIPTION =
  'These are the five largest outgoing transactions associated with this alert, ranked by USD values in the past 24 hours since the alert was issued.';

export const AnomalyTransactionsTable = ({ row }: IAnomalyTransactionsTableProps) => {
  return (
    <Box sx={{ margin: 1 }}>
      <Box display="flex" alignItems="center" gap="5px">
        <Typography color="white.100" fontSize="20px" gutterBottom>
          Largest 5 Outflows (24Hrs)
        </Typography>
        <Tooltip arrow TransitionComponent={Zoom} placement="left" title={LARGEST_5_OUTFLOWS_DESCRIPTION}>
          <Box width="20px" height="100%">
            <InfoIcon color="#c4c4c4" />
          </Box>
        </Tooltip>
      </Box>
      <Table size="small" aria-label="transactions history">
        <TableHead>
          <StyledHeaderTableRow>
            <StyledHeaderCell>Transaction Hash</StyledHeaderCell>
            <StyledHeaderCell>From Address</StyledHeaderCell>
            <StyledHeaderCell>To Address</StyledHeaderCell>
            <StyledHeaderCell>Timestamp</StyledHeaderCell>
            <StyledHeaderCell>Token</StyledHeaderCell>
            <StyledHeaderCell>Token Amount</StyledHeaderCell>
            <StyledHeaderCell>USD Amount</StyledHeaderCell>
          </StyledHeaderTableRow>
        </TableHead>
        <TableBody>
          {row.counterParties.map((data, index) => {
            //if the type is incorrect, render ethereum href
            const chain = isChainKey(data.chain) ? data.chain : 'ethereum';
            const trxHashHref = adjustTrxHashHref(chain, data.trxHash);
            const fromAddressHref = adjustAddressHref(chain, data.fromAddress);
            const toAddressHref = adjustAddressHref(chain, data.toAddress);

            return (
              <StyledTableRow key={`${data.type}-${index}`}>
                <StyledCell component="th" scope="row">
                  <StyledLink href={trxHashHref} underline="none" target="_blank">
                    <Typography noWrap width="150px" fontSize="14px">
                      {data.trxHash}
                    </Typography>
                  </StyledLink>
                </StyledCell>
                <StyledCell>
                  <StyledLink href={fromAddressHref} underline="none" target="_blank">
                    <Typography noWrap fontSize="14px">
                      {capitalizeFirstLetters(data.fromEntity)}
                    </Typography>
                  </StyledLink>
                </StyledCell>
                <StyledCell>
                  <StyledLink href={toAddressHref} underline="none" target="_blank">
                    <Typography noWrap fontSize="14px">
                      {capitalizeFirstLetters(data.toEntity)}
                    </Typography>
                  </StyledLink>
                </StyledCell>
                <StyledCell>
                  <Typography noWrap fontSize="14px">
                    {formatInTimeZone(new Date(data.timestamp), 'UTC', 'dd MMM, yyyy HH:mm:ss')} UTC
                  </Typography>
                </StyledCell>
                <StyledCell>
                  <Typography noWrap fontSize="14px">
                    {data.token}
                  </Typography>
                </StyledCell>
                <StyledCell>
                  <Typography noWrap fontSize="14px" align="right">
                    {formatNumberWithSuffix(data.nativeUnits)}
                  </Typography>
                </StyledCell>
                <StyledCell>
                  <Typography noWrap fontSize="14px" align="right">
                    {formatLargeDollarValueWithSuffix(data.usdSize)}
                  </Typography>
                </StyledCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
