import styled from '@emotion/styled';

export const StyledRiskZoneLabel = styled.div<{ color: string; fontSize?: string }>`
  display: flex;
  align-items: center;
  padding: 3px 10px;
  border-radius: 6px;
  width: fit-content;
  height: fit-content;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
  color: ${({ color }) => color};
  background-color: ${({ color }) => `${color}4D`};
`;
