import styled from '@emotion/styled';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { SIDE_BAR_WIDTH } from '@/layouts/MainLayout/constants';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: ${SIDE_BAR_WIDTH};
  height: 100vh;
  background-color: #101014;
  border-right: 1px solid #2e2e31;
  z-index: 1;

  * {
    box-sizing: border-box;
  }
`;

export const StyledLogoWrapper = styled.div`
  padding-block: 45px;
`;

export const StyledTopNavBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-block: 20px;
  border-bottom: 1px solid #2e2e31;
`;

export const StyledBottomNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  height: 100%;
`;

export const StyledSettingsPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 45px;
`;

export const StyledAdminPanelIcon = styled(AdminPanelSettingsOutlinedIcon)`
  color: #fff;
  transition: color 0.3s;

  &:hover {
    color: #5d51b8;
    cursor: pointer;
  }
`;

export const StyledMenu = styled(Menu)`
.MuiPaper-root {
  background-color: #222;
  color: #fff;
  box-shadow: 0 4px 10px rgba(0,0,0,0.3);
},
`;

export const StyledMenuItem = styled(MenuItem)`
  transition: background-color 0.3s;
&:hover {
  background-color: #444
},
`;
