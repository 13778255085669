import { TSeverity } from '@/types/severityLevel';

export const adjustSeverityColorFromValue = (severity: TSeverity): string => {
  switch (severity) {
    case 'extreme':
      return '#C00000';
    case 'high':
      return '#F60102';
    case 'medium':
      return '#FAC002';
    case 'low':
      return '#FEFF01';
    case 'none':
      return '#4EA72E';
    default:
      return '#808080';
  }
};

export const adjustSeverityLabel = (severity: TSeverity) => {
  if (severity === 'medium') {
    return 'Moderate';
  }

  return severity;
};

export const adjustSeverityToNumber = (severity: TSeverity): number | null => {
  switch (severity) {
    case 'extreme':
      return 4;
    case 'high':
      return 3;
    case 'medium':
      return 2;
    case 'low':
      return 1;
    case 'none':
      return 0;
    default:
      return null;
  }
};

export const adjustSeverityToNumberForAlertStatusTrending = (severity: TSeverity): number | null => {
  switch (severity) {
    case 'extreme':
      return 4;
    case 'high':
      return 3;
    case 'medium':
      return 2;
    case 'low':
      return 1.5;
    case 'none':
      return 1;
    default:
      return null;
  }
};

export const adjustSeverityLabelForAlertStatusTrending = (severity: number | undefined): string => {
  switch (severity) {
    case 4:
      return 'extreme';
    case 3:
      return 'high';
    case 2:
      return 'moderate';
    case 1.5:
      return 'low';
    case 1:
      return 'none';
    default:
      return '';
  }
};
