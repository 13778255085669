import styled from '@emotion/styled';
import ReactQuill from 'react-quill';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const StyledInputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;

export const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInputWrapper = styled.div`
  flex: 1 0 300px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
`;

export const StyledWrapperButton = styled.div`
  width: 253px;
`;

export const StyledCustomNotesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const StyledQuill = styled(ReactQuill)`
  .ql-editor {
    color: white;
  }

  .ql-editor.ql-blank::before {
    color: #ffffff80;
  }

  .ql-toolbar {
    background-color: white;
  }
`;
