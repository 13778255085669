import styled from '@emotion/styled';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-block: 16px;
  border-top: 1px solid #ffffff80;
  width: 100%;
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledItem = styled.li`
  display: flex;
  gap: 10px;
  color: #ffffff;
`;
