import { adjustRiskZoneColorFromValue } from '@/helpers/riskZoneMethods';
import { riskZones } from '@/constants/InitialState/riskZone';
import { StyledRiskZoneLabel } from './RiskZoneLabel.styled';
import { TRiskZone } from '@/types/riskZone';

interface IRiskZoneLabelProps {
  riskZone?: TRiskZone;
  fontSize?: string;
}

export const RiskZoneLabel = ({ riskZone, fontSize }: IRiskZoneLabelProps) => {
  if (riskZone === null || !riskZone) {
    riskZone = 'no_data';
  }

  const label = riskZones.find(({ value }) => value === riskZone);

  if (!label) return null;

  const riskZoneColor = adjustRiskZoneColorFromValue(riskZone);

  return (
    <StyledRiskZoneLabel color={riskZoneColor} fontSize={fontSize}>
      {label.name}
    </StyledRiskZoneLabel>
  );
};
