type CUSTOM_TYPOGRAPHY_KEYS =
  | 'AgioH1'
  | 'AgioH2'
  | 'AgioH3'
  | 'AgioH4'
  | 'AgioH5'
  | 'AgioH6'
  | 'normalText1'
  | 'normalText2'
  | 'normalText3'
  | 'normalText4'
  | 'normalText5'
  | 'normalText6'
  | 'normalText7'
  | 'status'
  | 'severity'
  | 'boxTitle'
  | 'chartTitle'
  | 'highChartDefault'
  | 'highChartDefaultBold'
  | 'highChartSmallBold'
  | 'highChartCustom'
  | 'dataGridBold'
  | 'dataGridRegular';

export type CustomTypography = {
  [key in CUSTOM_TYPOGRAPHY_KEYS]: React.CSSProperties;
};

export const typography: CustomTypography = {
  AgioH1: {
    fontSize: '24px',
    fontWeight: 600,
  },
  AgioH2: {
    fontSize: '22px',
    fontWeight: 600,
  },
  AgioH3: {
    fontSize: '20px',
    fontWeight: 600,
  },
  AgioH4: {
    fontSize: '18px',
    fontWeight: 600,
  },
  AgioH5: {
    fontSize: '16px',
    fontWeight: 600,
  },
  AgioH6: {
    fontSize: '14px',
    fontWeight: 600,
  },
  normalText1: {
    fontSize: '24px',
    fontWeight: 400,
  },
  normalText2: {
    fontSize: '22px',
    fontWeight: 400,
  },
  normalText3: {
    fontSize: '20px',
    fontWeight: 400,
  },
  normalText4: {
    fontSize: '18px',
    fontWeight: 400,
  },
  normalText5: {
    fontSize: '16px',
    fontWeight: 400,
  },
  normalText6: {
    fontSize: '14px',
    fontWeight: 400,
  },
  normalText7: {
    fontSize: '12px',
    fontWeight: 400,
  },
  status: {
    fontSize: '18px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  severity: {
    fontSize: '18px',
    fontWeight: 400,
  },
  boxTitle: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '8%',
    textTransform: 'uppercase',
  },
  chartTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22.6px',
  },
  highChartDefault: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12.8px',
    fontWeight: 400,
  },
  highChartDefaultBold: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12.8px',
    fontWeight: 600,
  },
  highChartSmallBold: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '11.2px',
    fontWeight: 600,
  },
  highChartCustom: {
    fontSize: '12.8px',
    fontWeight: 600,
  },
  dataGridBold: {
    fontSize: '14px',
    fontWeight: 600,
  },
  dataGridRegular: {
    fontSize: '14px',
    fontWeight: 400,
  },
};
