import styled from '@emotion/styled';
import Button from '@/components/atoms/Button/Button';
import Select from '@/components/molecules/Select/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper } from '@mui/material';

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  --stroke-color: #fff;
`;

export const StyledSelect = styled(Select)`
  width: 300px;
  height: 46px;
`;

export const StyledActionElementsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  border-radius: 4px;
  background-color: #101014;
  width: 250px;

  //Input border basic + on hover
  & fieldset,
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(255, 255, 255, 0.6);
  }

  //Button white text
  .MuiButtonBase-root {
    color: #fff;
  }

  //Input styles
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
    height: 46px;
  }
`;

export const StyledPaper = styled(Paper)`
  background: #1b1b1e;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.6);

  .MuiAutocomplete-noOptions {
    color: rgba(255, 255, 255, 0.6);
  }
`;
