import { Typography } from '@mui/material';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { formatLargeDollarValueWithSuffix } from '@/helpers/helpers';
import { ParameterWithTimer } from '@/views/EntityMonitoringView/components/EntityDetails/components/ParameterWithTimer/ParameterWithTimer';
import {
  StyledInternalWrapper,
  StyledSpacedBetweenRowWrapper,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';

interface IFlowsSectionProps {
  inflow: number | null;
  netflow: number | null;
  lastUpdated: string | null;
  outflow: number | null;
}

const ROLLING_FLOWS_TOOLTIP_DESCRIPTION =
  'This metric tracks the flows over a continuously updating 24-hour window. It reflects activity from the most recent data point, ensuring the flow data always represents the latest 24 hours, rather than a fixed calendar period.';

export const FlowsSection = ({ inflow, outflow, netflow, lastUpdated }: IFlowsSectionProps) => {
  if (!inflow && !outflow && !netflow) return null;

  return (
    <>
      <ParameterWithTimer
        parameter="Rolling 24H Flows"
        shouldDisplayTooltip
        tooltipDescription={ROLLING_FLOWS_TOOLTIP_DESCRIPTION}
        timestamp={lastUpdated ?? undefined}
      />

      <StyledInternalWrapper>
        {!!inflow && (
          <StyledSpacedBetweenRowWrapper>
            <ParameterWithTimer parameter="Inflows" />
            <Typography color="white.100" fontSize={FONT_SIZE} align="right">
              {formatLargeDollarValueWithSuffix(inflow)}
            </Typography>
          </StyledSpacedBetweenRowWrapper>
        )}
        {!!outflow && (
          <StyledSpacedBetweenRowWrapper>
            <ParameterWithTimer parameter="Outflows" />
            <Typography color="white.100" fontSize={FONT_SIZE} align="right">
              {formatLargeDollarValueWithSuffix(outflow)}
            </Typography>
          </StyledSpacedBetweenRowWrapper>
        )}
        {!!netflow && (
          <StyledSpacedBetweenRowWrapper>
            <ParameterWithTimer parameter="Netflows" />
            <Typography color={netflow && netflow > 0 ? 'green' : 'red'} fontSize={FONT_SIZE} align="right">
              {formatLargeDollarValueWithSuffix(netflow)}
            </Typography>
          </StyledSpacedBetweenRowWrapper>
        )}
      </StyledInternalWrapper>
    </>
  );
};
