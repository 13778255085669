import { useQuery } from '@tanstack/react-query';
import { CHANGELOG_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';

export const useGetChangelog = () => {
  const { changelogApi } = useApi();

  const {
    data: fetchedChangelog,
    isLoading,
    isSuccess,
    error,
  } = useQuery<string, Error>({
    queryKey: [CHANGELOG_KEY],
    queryFn: () => {
      return changelogApi.getChangelog().catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load changelog`)));
    },
  });

  return {
    fetchedChangelog,
    isLoading,
    isSuccess,
    error,
  };
};
