import { PersonalPreferencesForm } from '@/components/organims/PersonalPreferencesForm/PersonalPreferencesForm';
import { ChangePasswordForm } from '@/components/organims/ChangePasswordForm/ChangePasswordForm';
import { StyledContainer } from './SettingsView.styled';

export const SettingsView = () => {
  return (
    <StyledContainer>
      <PersonalPreferencesForm />

      <ChangePasswordForm />
    </StyledContainer>
  );
};
