import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridSortModel } from '@mui/x-data-grid';
import { adjustRiskZoneToNumber } from '@/helpers/riskZoneMethods';
import { adjustSeverityToNumber } from '@/helpers/severityLevelMethods';
import { TRiskZone } from '@/types/riskZone';
import { TSeverity } from '@/types/severityLevel';

export const useSortingManager = () => {
  const { dashboardType } = useParams();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const updateSortModel = (newModel: GridSortModel) => setSortModel(newModel);

  const sortingRiskZoneModel = sortModel.find((model) => model.field === 'riskZone');
  const sortingSeverityModel = sortModel.find(
    (model) => model.field === 'adjustedBalanceSeverity' || model.field === 'onChainActivitySeverity',
  );

  const isRiskZoneAscendant = useMemo(
    () => !!sortingRiskZoneModel && sortingRiskZoneModel.sort === 'asc',
    [sortingRiskZoneModel],
  );
  const isSeverityAscendant = useMemo(
    () => !!sortingSeverityModel && sortingSeverityModel.sort === 'asc',
    [sortingSeverityModel],
  );

  const riskZoneComparator = useCallback(
    (v1: TRiskZone, v2: TRiskZone) => {
      const riskZoneCode1 = adjustRiskZoneToNumber(v1);
      const riskZoneCode2 = adjustRiskZoneToNumber(v2);

      if (riskZoneCode1 === null && riskZoneCode2 === null) return 0;
      if (riskZoneCode1 === null) return isRiskZoneAscendant ? 1 : -1;
      if (riskZoneCode2 === null) return isRiskZoneAscendant ? -1 : 1;

      return riskZoneCode1 - riskZoneCode2;
    },
    [isRiskZoneAscendant],
  );
  const severityComparator = useCallback(
    (v1: TSeverity, v2: TSeverity) => {
      const severityCode1 = adjustSeverityToNumber(v1);
      const severityCode2 = adjustSeverityToNumber(v2);

      if (severityCode1 === null && severityCode2 === null) return 0;
      if (severityCode1 === null) return isSeverityAscendant ? 1 : -1;
      if (severityCode2 === null) return isSeverityAscendant ? -1 : 1;

      return severityCode1 - severityCode2;
    },
    [isSeverityAscendant],
  );

  useEffect(() => {
    if (dashboardType !== 'peak') return;
    const defaultSortModel: GridSortModel = [{ field: 'riskZone', sort: 'desc' }];

    updateSortModel(defaultSortModel);
  }, [dashboardType]);

  return {
    sortModel,
    updateSortModel,
    riskZoneComparator,
    severityComparator,
  };
};
