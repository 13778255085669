import { ParameterWithTimer } from '@/views/EntityMonitoringView/components/EntityDetails/components/ParameterWithTimer/ParameterWithTimer';
import { RatedEntity } from '@/views/EntityMonitoringView/components/EntityDetails/components/DefaultRisks/RatedEntity';
import { StyledSubSectionTitle } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const DefaultRisks = ({
  isRated,
  displayName,
  pd,
}: Pick<IEntityDetailsProps, 'isRated' | 'displayName' | 'pd'>) => {
  return (
    <>
      <StyledSubSectionTitle>Default Risk</StyledSubSectionTitle>

      {isRated ? <RatedEntity displayName={displayName} pd={pd} /> : <ParameterWithTimer parameter="Not Rated" />}
    </>
  );
};
