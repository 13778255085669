import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { createApiClient } from '@/providers/ApiContextProvider/createApiClient';
import { IApiContext } from '@/providers/ApiContextProvider/ApiContext.types';
import { assertIsDefined } from '@/helpers/assertions';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';

export const ApiContext = createContext<Partial<IApiContext>>({});

export const ApiProvider = ({ children }: PropsWithChildren) => {
  const { token, logOut } = useNewAuth();

  const API = useMemo(() => {
    return createApiClient(token || '', logOut);
  }, [token]);

  return <ApiContext.Provider value={API}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const {
    userApi,
    monitoringApi,
    adminUserApi,
    adminAlertsApi,
    adminEntityIds,
    adminRatingsApi,
    adminRisksApi,
    entityIdsApi,
    calculatorsApi,
    risksApi,
    ratingsApi,
    documentationApi,
    changelogApi,
  } = useContext(ApiContext);

  assertIsDefined(userApi, '"IApiContext.userApi has to be defined!"');
  assertIsDefined(monitoringApi, '"IApiContext.monitoringApi has to be defined!"');
  assertIsDefined(adminUserApi, '"IApiContext.adminUserApi has to be defined!"');
  assertIsDefined(adminAlertsApi, '"IApiContext.adminAlertsApi has to be defined!"');
  assertIsDefined(adminEntityIds, '"IApiContext.adminEntityIds has to be defined!"');
  assertIsDefined(adminRatingsApi, '"IApiContext.adminRatingsApi has to be defined!"');
  assertIsDefined(adminRisksApi, '"IApiContext.adminRisksApi has to be defined!"');
  assertIsDefined(entityIdsApi, '"IApiContext.entityIdsApi has to be defined!"');
  assertIsDefined(calculatorsApi, '"IApiContext.calculatorsApi has to be defined!"');
  assertIsDefined(risksApi, '"IApiContext.risksApi has to be defined!"');
  assertIsDefined(ratingsApi, '"IApiContext.ratingsApi has to be defined!"');
  assertIsDefined(documentationApi, '"IApiContext.documentationApi has to be defined!"');
  assertIsDefined(changelogApi, '"IApiContext.changelogApi has to be defined!"');

  return {
    userApi,
    monitoringApi,
    adminUserApi,
    adminAlertsApi,
    adminEntityIds,
    adminRatingsApi,
    adminRisksApi,
    entityIdsApi,
    calculatorsApi,
    risksApi,
    ratingsApi,
    documentationApi,
    changelogApi,
  };
};
