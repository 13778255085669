import { createContext, PropsWithChildren } from 'react';
import {
  IAnomaliesContext,
  IAnomaliesProviderProps,
} from '@/views/EntityMonitoringView/context/AnomaliesProvider.types';
import { useAnomaliesManager } from '@/views/EntityMonitoringView/context/AnomaliesProvider.hooks';

export const AnomaliesContext = createContext<Partial<IAnomaliesContext>>({});

export const AnomaliesProvider = ({ children, latestAnomalies }: PropsWithChildren<IAnomaliesProviderProps>) => {
  const {
    uniqueSortedAnomalies,
    anomaliesTransactionDetailsVisibility,
    toggleAnomalyDetailsVisibility,
    handleScrollAndTransactionsVisibility,
    anomaliesSectionRef,
    anomaliesTableContainerRef,
    anomaliesTableStickyHeaderRef,
  } = useAnomaliesManager(latestAnomalies);

  const VALUE: IAnomaliesContext = {
    uniqueSortedAnomalies,
    anomaliesTransactionDetailsVisibility,
    toggleAnomalyDetailsVisibility,
    handleScrollAndTransactionsVisibility,
    anomaliesSectionRef,
    anomaliesTableContainerRef,
    anomaliesTableStickyHeaderRef,
  };

  return <AnomaliesContext.Provider value={VALUE}>{children}</AnomaliesContext.Provider>;
};
