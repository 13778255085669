import { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isNumber, mapValues } from 'lodash';
import { Typography } from '@mui/material';
import HelpIcon from '@/assets/icons/help.svg?react';
import { CUSTOM_SWITCH_SX } from '@/components/atoms/CustomSwitch/ControlledCustomSwitch';
import { sortAlphabetically } from '@/helpers/helpers';
import {
  compareRisksDates,
  mapEntityNameToRisksDecomposition,
  prepareChartData,
  RiskDecompositionDataPoint,
} from '@/components/RisksDecompositions/helpers';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import Loader from '@/components/atoms/Loader/Loader';
import Select from '@/components/molecules/Select/Select';
import { variables } from '@/components/RisksDecompositions/constants';
import VariableName from '@/components/RisksDecompositions/VariableName';
import VariableValue from '@/components/RisksDecompositions/VariableValue';
import RisksDecompositionChart from '@/components/RisksDecompositions/RisksDecompositionChart';
import {
  StyledDateContainer,
  StyledDatePicker,
  StyledHeaderName,
  StyledRow,
  StyledSecondRow,
  StyledSmallContainer,
  StyledTableRow,
  StyledTitle,
  StyledTitleRow,
  StyledTopRow,
  StyledVariablesTable,
  StyledVariableTitle,
} from '@/components/RisksDecompositions/RisksDecompositions.styled';
import { StyledCustomSwitch, StyledFormControlLabel } from '@/components/atoms/CustomSwitch/CustomSwitch.styled';
import { IDatePickerRange } from '@/types/datePicker';
import { IRisksDecompositionsProps } from '@components/RisksDecompositions/RisksDecompositions';
import { IGetRisksDecompositionResponse } from '@/api/risks/risks.types';

interface IRiskDecompositionsContentProps extends IRisksDecompositionsProps {
  fetchedRisksDecomposition: IGetRisksDecompositionResponse;
}

export const RiskDecompositionsContent = ({
  originalEntityName,
  statisticalRatings,
  fetchedRisksDecomposition,
}: IRiskDecompositionsContentProps) => {
  const [selectedExchange, setSelectedExchange] = useState<string>('');
  const [isToggleByDate, setIsToggleByDate] = useState<boolean>(false);
  const toggleLabel = isToggleByDate ? 'Toggle to compare by exchanges' : 'Toggle to compare by dates';

  const availableDatesRange: IDatePickerRange = useMemo(() => {
    const fetchedStart =
      fetchedRisksDecomposition.risksDecomposition[0].data[
        fetchedRisksDecomposition.risksDecomposition[0].data.length - 1
      ].date.split('T')[0];
    const fetchedEnd = fetchedRisksDecomposition.risksDecomposition[0].data[0].date.split('T')[0];

    return {
      start: dayjs(fetchedStart),
      end: dayjs(fetchedEnd),
    };
  }, [fetchedRisksDecomposition]);

  const [selectedEntityDate, setSelectedEntityDate] = useState<Dayjs>(availableDatesRange.end);
  const [selectedCompareDate, setSelectedCompareDate] = useState<Dayjs>(availableDatesRange.end);

  const handleEntityDatePickerChange = (newValue: unknown) => {
    if (dayjs.isDayjs(newValue)) {
      setSelectedEntityDate(newValue);
    }
  };

  const handleCompareDatePickerChange = (newValue: unknown) => {
    if (dayjs.isDayjs(newValue)) {
      setSelectedCompareDate(newValue);
    }
  };

  const selectedEntityDateTimestamp = useMemo(() => {
    return selectedEntityDate.format('YYYY-MM-DD');
  }, [selectedEntityDate]);

  const selectedCompareDateTimestamp = useMemo(() => {
    return selectedCompareDate.format('YYYY-MM-DD');
  }, [selectedCompareDate]);

  const handleOnChangeExchange = (value: string) => {
    setSelectedExchange(value);
  };

  const exchangeList = useMemo(() => {
    const exchanges = statisticalRatings.map((sr) => sr.entity);

    return sortAlphabetically(exchanges);
  }, [statisticalRatings]);

  const mappedEntityName = mapEntityNameToRisksDecomposition(originalEntityName);
  const entityData = fetchedRisksDecomposition.risksDecomposition.find((r) => r.entity === mappedEntityName);
  const riskData = statisticalRatings.find((sr) => sr.entity === originalEntityName);
  const entityPD = riskData?.data.find((sr) => compareRisksDates(selectedEntityDateTimestamp, sr.timestamp));
  const entityValues = entityData?.data
    ? entityData.data.find((d: RiskDecompositionDataPoint) => compareRisksDates(selectedEntityDateTimestamp, d.date))
    : undefined;

  const comparedByDateEntityPD = riskData?.data.find((sr) =>
    compareRisksDates(selectedCompareDateTimestamp, sr.timestamp),
  );
  const comparedByDateEntityValues = entityData?.data
    ? entityData.data.find((d: RiskDecompositionDataPoint) => compareRisksDates(selectedCompareDateTimestamp, d.date))
    : undefined;

  const comparedByExchangeEntityData = fetchedRisksDecomposition.risksDecomposition.find(
    (r) => r.entity === mapEntityNameToRisksDecomposition(selectedExchange),
  );
  const comparedByExchangeEntityRisksData = statisticalRatings.find((r) => r.entity === selectedExchange);
  const comparedByExchangeEntityPD = comparedByExchangeEntityRisksData?.data.find((sr) =>
    compareRisksDates(selectedEntityDateTimestamp, sr.timestamp),
  );
  const comparedByExchangeValues = comparedByExchangeEntityData?.data
    ? comparedByExchangeEntityData.data.find((d: RiskDecompositionDataPoint) =>
        compareRisksDates(selectedEntityDateTimestamp, d.date),
      )
    : undefined;

  if (!entityValues) {
    return (
      <StyledSmallContainer style={{ height: '561px' }}>
        <Loader />
      </StyledSmallContainer>
    );
  }

  const formattedEntityValues = mapValues(entityValues, (value) => (isNumber(value) ? Math.round(value) : value));
  const formattedComparedByDateEntityValues = mapValues(comparedByDateEntityValues, (value) =>
    isNumber(value) ? Math.round(value) : value,
  );
  const formattedComparedByExchangeValues = mapValues(comparedByExchangeValues, (value) =>
    isNumber(value) ? Math.round(value) : value,
  );

  const originalExchangeName = getFullExchangeName(mappedEntityName || '');
  const comparedExchangeName = getFullExchangeName(selectedExchange);

  const comparisonByExchangeChartData = prepareChartData(
    formattedEntityValues,
    formattedComparedByExchangeValues,
    originalExchangeName,
    comparedExchangeName,
  );

  const comparisonByDateChartData = prepareChartData(
    formattedEntityValues,
    formattedComparedByDateEntityValues,
    selectedEntityDateTimestamp,
    selectedCompareDateTimestamp,
  );

  return (
    <StyledSmallContainer>
      <StyledTopRow>
        <StyledTitle>KEY RISK DRIVERS</StyledTitle>
        <Tooltip
          arrow
          title={
            <>
              <p>
                The table below describes the 10 key risk drivers of an exchange’s 1-YR PD. More risk drivers are
                included in the Statistical Ratings model; however, only the ones with the highest impact are displayed
                below.
              </p>
              <p>
                The chart plots the key risk scores (out of 100) for the exchange. The score has been adjusted to
                reflect the performance of the exchange with respect to the wider pool of exchanges. A low score
                indicates that the exchange is doing poorly when compared to the rest of the exchanges analysed by Agio
                Ratings. Conversely, a high score indicates that the exchange is performing well when compared to the
                rest of the exchanges.
              </p>
              <p>
                Additionally, the user can select a different exchange to display a barbell chart. The barbell compares
                the two exchanges’ key risk drivers. It helps visualise where two exchanges have different sources of
                risk.
              </p>
            </>
          }
        >
          <div>
            <HelpIcon />
          </div>
        </Tooltip>
      </StyledTopRow>

      <StyledSecondRow>
        <Typography color="white.100" fontWeight="bold" fontSize="24px" pl="200px" width="440px" align="center">
          {getFullExchangeName(originalEntityName || '')}
        </Typography>
        <StyledFormControlLabel
          control={
            <StyledCustomSwitch
              id="toggle_comparator"
              sx={CUSTOM_SWITCH_SX}
              checked={isToggleByDate}
              onChange={() => setIsToggleByDate((prev) => !prev)}
            />
          }
          label={toggleLabel}
          labelPlacement="start"
        />
      </StyledSecondRow>

      <StyledRow>
        <StyledVariablesTable>
          <thead>
            <tr>
              <StyledHeaderName>Variable</StyledHeaderName>
              <StyledHeaderName entityCell={true}>
                <p>Selected date: </p>
                <StyledDateContainer>
                  <StyledDatePicker
                    name="select-date"
                    format="YYYY-MM-DD"
                    minDate={availableDatesRange.start}
                    maxDate={availableDatesRange.end}
                    value={selectedEntityDate}
                    onChange={handleEntityDatePickerChange}
                  />
                </StyledDateContainer>
              </StyledHeaderName>
              <StyledHeaderName>
                <StyledDateContainer>
                  {isToggleByDate ? (
                    <>
                      <p>Comparison date: </p>
                      <StyledDatePicker
                        name="select-compare-date"
                        format="YYYY-MM-DD"
                        minDate={availableDatesRange.start}
                        maxDate={availableDatesRange.end}
                        value={selectedCompareDate}
                        onChange={handleCompareDatePickerChange}
                      />
                    </>
                  ) : (
                    <>
                      <p>Comparison entity: </p>
                      <Select
                        options={exchangeList.map((e) => ({ label: getFullExchangeName(e), value: e }))}
                        onChange={handleOnChangeExchange}
                        label={'Exchange'}
                        value={selectedExchange}
                        placeholder={'Compare'}
                      />
                    </>
                  )}
                </StyledDateContainer>
              </StyledHeaderName>
            </tr>
          </thead>

          <tbody>
            {variables.map((v) => (
              <StyledTableRow key={v.name}>
                <td>
                  <VariableName title={v.name} hint={v.hint} />
                </td>
                <td style={{ borderRight: '1px solid #FFF' }}>
                  <VariableValue
                    color="#9388E8"
                    value={
                      //@ts-expect-error this is issue with highcharts
                      formattedEntityValues[v.key]
                    }
                  />
                </td>
                <td>
                  <VariableValue
                    color="#DDDDDD"
                    value={
                      isToggleByDate
                        ? //@ts-expect-error this is issue with highcharts
                          v.key && formattedComparedByDateEntityValues && formattedComparedByDateEntityValues[v.key]
                        : //@ts-expect-error this is issue with highcharts
                          v.key && formattedComparedByExchangeValues && formattedComparedByExchangeValues[v.key]
                    }
                  />
                </td>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <td>
                <StyledVariableTitle color="#F7F7F7">1-Year Probability of Default</StyledVariableTitle>
              </td>
              <td style={{ borderRight: '1px solid #FFF' }}>
                <VariableValue color="#9388E8" value={entityPD?.value || undefined} addPercentage />
              </td>
              <td>
                <VariableValue
                  color="#DDDDDD"
                  value={
                    (isToggleByDate ? comparedByDateEntityPD?.value : comparedByExchangeEntityPD?.value) || undefined
                  }
                  addPercentage
                />
              </td>
            </StyledTableRow>
          </tbody>
        </StyledVariablesTable>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <StyledTitleRow>
            <div style={{ color: '#B51C1C', fontWeight: 600 }}>Lowest Score</div>
            <div style={{ color: '#E5B302', fontWeight: 600 }}>Average Score</div>
            <div style={{ color: '#4DBA32', fontWeight: 600 }}>Healthiest Score</div>
          </StyledTitleRow>
          <RisksDecompositionChart data={isToggleByDate ? comparisonByDateChartData : comparisonByExchangeChartData} />
        </div>
      </StyledRow>
    </StyledSmallContainer>
  );
};
