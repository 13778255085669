import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useGetEntityById } from '@/api/adminEntityIds/adminEntityIds.hooks';
import Loader from '@/components/atoms/Loader/Loader';
import Text from '@/components/atoms/Text/Text';
import { EditEntityDetailsForm } from '@/views/AdminPanelView/components/Entities/AdminPanelEntityDetails/components/EditEntityDetailsForm/EditEntityDetailsForm';
import {
  StyledAdminButton,
  StyledEntityDetailsContainer,
} from '@/views/AdminPanelView/components/Entities/AdminPanelEntityDetails/AdminPanelEntityDetails.styled';

export const AdminPanelEntityDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { fetchedEntity, isLoading, error, isSuccess } = useGetEntityById(id || '');

  if (isLoading) {
    return <Loader />;
  }

  if (error) return <Typography color="white.100">Error getting entity details: {error.message}</Typography>;

  if (!fetchedEntity || (isSuccess && fetchedEntity && !fetchedEntity.id))
    return <Typography color="white.100">There is no entity details to display.</Typography>;

  const handleViewLogHistoryClick = () => navigate(`event_log`);

  return (
    <StyledEntityDetailsContainer>
      <StyledAdminButton as={'a'} onClick={handleViewLogHistoryClick}>
        View Log History
      </StyledAdminButton>

      <Text fontSize="20px">Entity Details</Text>
      <EditEntityDetailsForm entity={fetchedEntity} />
    </StyledEntityDetailsContainer>
  );
};
