import styled from '@emotion/styled';
import { AppBar, Toolbar } from '@mui/material';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';
import Button from '@/components/atoms/Button/Button';

export const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

export const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  overflow: auto;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
`;
