import { adjustSeverityColorFromValue, adjustSeverityLabel } from '@/helpers/severityLevelMethods';
import { StyledSeverityWrapper } from '@/components/SeverityLabel/SeverityLabel.styled';
import { TSeverity } from '@/types/severityLevel';

interface ISeverityLabelProps {
  severity: TSeverity;
  fontSize?: string;
}

export const SeverityLabel = ({ severity, fontSize }: ISeverityLabelProps) => {
  const severityColor = adjustSeverityColorFromValue(severity);
  return (
    <StyledSeverityWrapper color={severityColor} fontSize={fontSize}>
      {severity ? adjustSeverityLabel(severity) : 'Data unavailable'}
    </StyledSeverityWrapper>
  );
};
