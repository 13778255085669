import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import { DocumentationChangelogView } from '@/views/DocumentationChangelogView/DocumentationChangelogView';

const ChangelogPage = () => {
  const { shouldDisplayDocumentation } = usePermissionsManager();

  if (!shouldDisplayDocumentation) {
    return <NoPermissionsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <DocumentationChangelogView />
    </ErrorBoundary>
  );
};

export default ChangelogPage;
