import { useMemo } from 'react';
import { mapChainData } from '@/helpers/mapChartsData';
import { DISABLE_CHART_EXPORTING_MENU, RESET_ZOOM_BUTTON_POSITION } from '@/constants/chartOptions';
import { CHART_BG_COLOR, GRID_LINE_COLOR } from '@/views/EntityMonitoringView/components/SyncCharts/utils/constants';
import {
  balanceChartTickPositioner,
  balancesTooltipFormatter,
} from '@/views/EntityMonitoringView/components/SyncCharts/utils/helpers';
import { ISyncChartsProps } from '@/views/EntityMonitoringView/components/SyncCharts/SyncCharts';

export const useBalancesChartManager = ({ balances }: Pick<ISyncChartsProps, 'balances'>) => {
  const balanceChartData = useMemo(() => {
    return mapChainData(balances).sort((a, b) => a.x - b.x);
  }, [balances]);

  const timestampOfLastDataPoint = balanceChartData?.length ? balanceChartData[balanceChartData?.length - 1].x : null;
  const timestampOfFirstDataPoint = balanceChartData?.length ? balanceChartData[0].x : null;

  const balanceChartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: CHART_BG_COLOR,
      height: 300,
      marginLeft: 120,
      zoomType: 'x',
      ...RESET_ZOOM_BUTTON_POSITION,
    },
    tooltip: {
      backgroundColor: '#1B1B1E',
      borderRadius: 8,
      outside: true,
      style: {
        color: '#ffffff',
        opacity: 1,
      },
      useHTML: true,
      shared: true,
      formatter: balancesTooltipFormatter,
    },
    title: {
      text: undefined,
    },
    subtitle: {
      text: undefined,
    },
    xAxis: {
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineColor: GRID_LINE_COLOR,
      max: timestampOfLastDataPoint,
      min: timestampOfFirstDataPoint,
      type: 'datetime',
      tickLength: 0,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      tickPositioner: balanceChartTickPositioner,
      labels: {
        style: {
          color: '#F7F7F7',
        },
      },
    },
    yAxis: {
      gridLineColor: GRID_LINE_COLOR,
      title: {
        text: undefined,
      },
      labels: {
        formatter: function (this: { value: number }): string {
          if (this.value >= 1e9) {
            return '$' + this.value / 1e9 + 'B';
          }
          if (this.value >= 1e6) {
            return '$' + this.value / 1e6 + 'M';
          }

          if (this.value >= 1e3) {
            return '$' + this.value / 1e3 + 'k';
          }

          return '$' + this.value;
        },
        style: {
          color: '#F7F7F7',
          lineHeight: '15.809px',
        },
      },
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      layout: 'horizontal',
      useHTML: true,
      title: {
        text: '<div style="color:#F7F7F7; width: 165px;">Total Observable Balance</div>',
        style: {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22.584px',
          color: '#F7F7F7',
        },
      },
      itemStyle: {
        color: '#FFFFFF80',
      },
      itemHoverStyle: {
        color: '#FFFFFF',
      },
    },
    plotOptions: {
      area: {
        trackByArea: true,
        enableMouseTracking: true,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, 'rgba(93, 81, 184, 0.28)'],
            [0.8956, 'rgba(93, 81, 184, 0)'],
          ],
        },
        marker: {
          radius: 1,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    series: [
      {
        type: 'area',
        name: 'Total Observable Balance',
        color: '#5D51B8',
        data: balanceChartData,
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
    ],
    time: {
      useUTC: false,
    },
    ...DISABLE_CHART_EXPORTING_MENU,
  };

  return {
    shouldRenderBalancesChart: !!balanceChartData.length,
    balanceChartOptions,
  };
};
