import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldErrors, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { defaultMonitoringFormValues } from '@/views/MonitoringSettingsView/utils/constants';
import {
  useCreateMonitoringSettings,
  useEditUser,
  useGetMonitoringSettings,
  useGetUserById,
} from '@/api/user/user.hooks';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { useBlockNavigation } from '@/hooks/useBlockNavigation';
import { useTracking } from '@/hooks/useTracking';
import { ERoutes } from '@/types/routes';
import { ELocalStorage } from '@/types/storage';
import {
  AnomaliesEmailNotificationsOptionKey,
  IMonitoringForm,
  MonitoringSchema,
} from '@/views/MonitoringSettingsView/utils/types';

export const useMonitoringForm = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { exchanges, getExchangeLabelByDisplayName, getDisplayNameByExchangeLabel } = useEntitiesList();
  const user = localStorage.getItem(ELocalStorage.USER);
  const userId: string = user && JSON.parse(user).userId;

  const monitoringMethods = useForm<IMonitoringForm>({
    defaultValues: defaultMonitoringFormValues,
    resolver: zodResolver(MonitoringSchema),
    mode: 'all',
  });

  const { createMonitoringSettings, isLoading: isCreateMonitoringSettingsLoading } = useCreateMonitoringSettings();
  const { editUser, isLoading: isEditUserEmailNotificationsSettingsLoading } = useEditUser();

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    setFocus,
  } = monitoringMethods;

  useBlockNavigation(isDirty);

  const generateEntitiesSettings = (data: IMonitoringForm) => {
    switch (data.emailNotifications.notificationsMode) {
      case 'all':
        return null;
      case 'none':
        return [];
      case 'custom':
        return data.entities.map((entity) => getExchangeLabelByDisplayName(entity));
      case 'select':
        return data.emailNotifications.selectedEntities.map((entity) => getExchangeLabelByDisplayName(entity));
      default:
        return null;
    }
  };

  const onMonitoringSubmit = useCallback(
    async (data: IMonitoringForm) => {
      if (!userId) return;

      const monitoringEntitiesPayload = {
        entities: data.entities.map((e) => getExchangeLabelByDisplayName(e)),
      };

      const emailNotificationsEntities = generateEntitiesSettings(data);

      const emailNotificationsPayload = {
        id: userId,
        notificationSettings: { entities: emailNotificationsEntities },
      };

      createMonitoringSettings(monitoringEntitiesPayload, {
        onSuccess: () => {
          trackEvent('Monitoring settings/entities to monitor - save', {
            entities: monitoringEntitiesPayload.entities,
          });

          editUser(emailNotificationsPayload, {
            onSuccess: () => {
              trackEvent('Monitoring settings/email notifications - save', {
                entities: emailNotificationsPayload.notificationSettings.entities,
              });
            },
            onError: (error) => {
              console.error('Monitoring settings - email notifications setup failed: ', error.message);
            },
          });

          navigate(`${ERoutes.MONITORING}/custom`);
        },
        onError: (error) => {
          console.error('Monitoring settings - entities to monitor setup failed: ', error.message);
        },
      });
    },
    [userId, createMonitoringSettings, getExchangeLabelByDisplayName, trackEvent, editUser, navigate],
  );

  const onMonitoringError = (errors: FieldErrors<IMonitoringForm>) => {
    if (errors.entities) {
      setFocus('entities');
    }

    if (errors.emailNotifications) {
      setFocus('emailNotifications.notificationsMode');
    }
  };

  const handleMonitoringFormSubmit = handleSubmit(onMonitoringSubmit, onMonitoringError);

  const {
    fetchedSettings,
    isSuccess: isGetMonitoringSettingsSuccess,
    isLoading: isGetMonitoringSettingsLoading,
  } = useGetMonitoringSettings();

  const {
    fetchedUser,
    isSuccess: isGetUserEmailNotificationsSettingsSuccess,
    isLoading: isGetUserEmailNotificationsSettingsLoading,
  } = useGetUserById({ id: userId });

  const adjustEmailNotifications = (entitiesToNotification: string[] | null, entitiesToMonitor: string[]) => {
    if (entitiesToNotification === null) {
      return defaultMonitoringFormValues.emailNotifications;
    }

    if (entitiesToNotification.length === 0) {
      return {
        notificationsMode: 'none' as AnomaliesEmailNotificationsOptionKey,
        selectedEntities: defaultMonitoringFormValues.emailNotifications.selectedEntities,
      };
    }

    const areEntitiesToEmailNotificationEqualToEntitiesToMonitor = _.isEqual(entitiesToNotification, entitiesToMonitor);
    const mappedEntities = entitiesToNotification.map((e) => getDisplayNameByExchangeLabel(e));

    return {
      notificationsMode: areEntitiesToEmailNotificationEqualToEntitiesToMonitor
        ? 'custom'
        : ('select' as AnomaliesEmailNotificationsOptionKey),
      selectedEntities: mappedEntities,
    };
  };

  useEffect(() => {
    if (
      !fetchedSettings ||
      !isGetMonitoringSettingsSuccess ||
      !exchanges.length ||
      !fetchedUser ||
      !fetchedUser.notificationSettings ||
      !isGetUserEmailNotificationsSettingsSuccess
    )
      return;

    const emailNotifications = adjustEmailNotifications(
      fetchedUser.notificationSettings.entities,
      fetchedSettings.entities,
    );

    if (fetchedSettings.entities.length > 0) {
      const fetchedEntities = fetchedSettings.entities.map((e) => getDisplayNameByExchangeLabel(e));

      const formState = {
        entities: fetchedEntities,
        emailNotifications,
      };

      return reset(formState);
    }

    const formState = {
      entities: defaultMonitoringFormValues.entities,
      emailNotifications,
    };

    reset(formState);
  }, [
    exchanges,
    fetchedSettings,
    fetchedUser,
    getDisplayNameByExchangeLabel,
    isGetMonitoringSettingsSuccess,
    isGetUserEmailNotificationsSettingsSuccess,
    reset,
  ]);

  return {
    monitoringMethods,
    handleMonitoringFormSubmit,
    isCreateMonitoringSettingsLoading,
    isGetMonitoringSettingsLoading,
    isEditUserEmailNotificationsSettingsLoading,
    isGetUserEmailNotificationsSettingsLoading,
  };
};
