export enum ApiEndpoints {
  ADMIN_USER = '/admin/user/',
  ADMIN_ALERTS = '/admin/alerts/',
  ADMIN_ENTITY_IDS = '/admin/entity_ids/',
  ADMIN_RATINGS = '/admin/ratings/',
  ADMIN_RISKS = '/admin/risks/',
  AUTH_USER = '/auth/token/',
  USER = '/user',
  MONITORING = '/monitoring/',
  ENTITY_IDS = '/entity_ids/',
  CALCULATORS = '/loss_calculator',
  RISKS = '/risks/',
  RATINGS = '/ratings/',
  DOCUMENTATION = '/documentation/',
  CHANGELOG = '/changelog.md',
}

export enum ERoutes {
  BASE = '/',
  ALERTS = '/alerts',
  RATINGS = '/ratings',
  RISK_SIMULATOR = '/risk-simulator',
  RISK_SIMULATOR_PORTFOLIO = '/risk-simulator/portfolio',
  MONITORING = '/monitoring',
  MONITORING_ENTITY = '/monitoring/entity',
  MONITORING_SETTINGS = '/monitoring/settings',
  SETTINGS = '/settings',
  ADMIN = '/admin',
  DOCUMENTATION_CHANGELOG = '/documentation/changelog',
}

export enum EAdminPaths {
  ALERTS = 'alerts',
  USERS = 'users',
  ENTITIES = 'entities',
  ANALYTICS = 'analytics',
  SETTINGS = 'settings',
}
