import { useMemo } from 'react';
import { compareAsc, parseISO } from 'date-fns';
import { max, min, range } from 'lodash';
import {
  mapAlertStatusChartDataBasedOnSyncTimestamps,
  mapAnomalyChartDataFromRiskZones,
  mapFlowsChartDataBasedOnSyncTimestamp,
  mapNetflowsChainDataBasedOnSyncInflowsAndOutflows,
} from '@/views/EntityMonitoringView/components/SyncCharts/utils/helpers';
import { adjustRiskZoneToNumberForAlertStatusTrending } from '@/helpers/riskZoneMethods';
import { adjustSeverityToNumberForAlertStatusTrending } from '@/helpers/severityLevelMethods';
import { useAnomalies } from '@/views/EntityMonitoringView/context/AnomaliesProvider.hooks';
import { ISyncChartsProps } from '@/views/EntityMonitoringView/components/SyncCharts/SyncCharts';

export const useSyncChartsDatasetsManager = ({
  riskZones,
  hourlyInflowData,
  hourlyOutflowData,
}: Omit<ISyncChartsProps, 'balances'>) => {
  const riskZonesSortedAsc = useMemo(() => {
    return [...riskZones].sort((a, b) => {
      return compareAsc(parseISO(a.timestamp), parseISO(b.timestamp));
    });
  }, [riskZones]);

  // Pick the time range

  const riskZonesTimestamps = useMemo(() => {
    return riskZonesSortedAsc.map((riskZone) => new Date(riskZone.timestamp).getTime());
  }, [riskZonesSortedAsc]);

  const inflowsTimestamps = useMemo(() => {
    return hourlyInflowData.map((inflow) => new Date(inflow.timestamp).getTime());
  }, [hourlyInflowData]);

  const outflowsTimestamps = useMemo(() => {
    return hourlyOutflowData.map((outflow) => new Date(outflow.timestamp).getTime());
  }, [hourlyOutflowData]);

  const allTimestamps = useMemo(() => {
    const allGatheredTimestamps = [
      ...riskZonesTimestamps.map((d) => d),
      ...inflowsTimestamps.map((d) => d),
      ...outflowsTimestamps.map((d) => d),
    ];

    const minTime = min(allGatheredTimestamps);
    const maxTime = max(allGatheredTimestamps);

    if (minTime && maxTime) {
      const oneHourStep = 60 * 60 * 1000;
      return range(minTime, maxTime + oneHourStep, oneHourStep);
    }

    return [];
  }, [inflowsTimestamps, outflowsTimestamps, riskZonesTimestamps]);

  // Alert Status Chart

  const alertStatusFixedDataset = useMemo(() => {
    return mapAlertStatusChartDataBasedOnSyncTimestamps({
      data: riskZonesSortedAsc,
      timestamps: allTimestamps,
      filterKey: 'riskZone',
      valueMapper: adjustRiskZoneToNumberForAlertStatusTrending,
    });
  }, [allTimestamps, riskZonesSortedAsc]);

  const netflowSeverityFixedDataset = useMemo(() => {
    return mapAlertStatusChartDataBasedOnSyncTimestamps({
      data: riskZonesSortedAsc,
      timestamps: allTimestamps,
      filterKey: 'netflow',
      valueMapper: adjustSeverityToNumberForAlertStatusTrending,
    });
  }, [allTimestamps, riskZonesSortedAsc]);

  const { uniqueSortedAnomalies } = useAnomalies();

  const anomaliesDataset = useMemo(() => {
    return mapAnomalyChartDataFromRiskZones(riskZonesSortedAsc, uniqueSortedAnomalies).sort((a, b) => a.x - b.x);
  }, [riskZonesSortedAsc, uniqueSortedAnomalies]);

  // Flows Chart

  const inflowDataFixedDataset = useMemo(() => {
    return mapFlowsChartDataBasedOnSyncTimestamp(hourlyInflowData, allTimestamps);
  }, [allTimestamps, hourlyInflowData]);

  const outflowDataFixedDataset = useMemo(() => {
    return mapFlowsChartDataBasedOnSyncTimestamp(hourlyOutflowData, allTimestamps, true);
  }, [allTimestamps, hourlyOutflowData]);

  const netflowDataFixedDataset = useMemo(() => {
    return mapNetflowsChainDataBasedOnSyncInflowsAndOutflows(inflowDataFixedDataset, outflowDataFixedDataset);
  }, [inflowDataFixedDataset, outflowDataFixedDataset]);

  return {
    areSyncTimestampsAvailable: !!allTimestamps.length,
    alertStatusChart: {
      alertStatusData: alertStatusFixedDataset,
      netflowData: netflowSeverityFixedDataset,
      anomalyData: anomaliesDataset,
    },
    flowsChart: {
      inflowData: inflowDataFixedDataset,
      outflowData: outflowDataFixedDataset,
      netflowData: netflowDataFixedDataset,
    },
  };
};
