import { AppBar, Toolbar, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

export const SettingsHeader = () => {
  return (
    <StyledHeader position="static">
      <Toolbar>
        <Typography variant="h4" style={{ flexGrow: 1 }} noWrap>
          Settings
        </Typography>
      </Toolbar>
    </StyledHeader>
  );
};
