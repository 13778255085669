import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { assertIsDefined } from '@/helpers/assertions';
import { sortAlphabetically } from '@/helpers/helpers';
import { sortAscEntitiesByLabel } from '@/providers/EntitiesListContextProvider/helpers';
import { EntitiesListContext } from '@/providers/EntitiesListContextProvider/EntitiesListContextProvider';
import { useGetUniqueEntitiesList } from '@/api/entityIds/entityIds.hooks';
import { useGetUniqueEntitiesListByAdmin } from '@/api/adminEntityIds/adminEntityIds.hooks';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';

const useEntitiesByTypeManager = (exchanges: IExchange[]) => {
  //IMPORTANT!!! List of exchanges may include exchanges with type as null!
  const listOfEntityTypes = useMemo(() => {
    if (!exchanges.length) return [];

    const availableTypes = Array.from(
      new Set(exchanges.map((exchange) => exchange.type).filter((e): e is string => e !== null)),
    );

    return sortAlphabetically(availableTypes);
  }, [exchanges]);

  const listOfEntityTypesEnabledForUser = useMemo(() => {
    return listOfEntityTypes.filter((type) => type !== 'gambling' && type !== 'misc' && type !== 'infrastructure');
  }, [listOfEntityTypes]);

  const sortedEntitiesByEnabledType = useMemo(() => {
    if (!exchanges.length || !listOfEntityTypesEnabledForUser.length) return [];

    const sortedBucket: IExchange[] = [];

    listOfEntityTypesEnabledForUser.forEach((type, i) => {
      exchanges.forEach((exchange) => {
        if (exchange.type === type) {
          sortedBucket.push(exchange);
        }
      });
    });

    return sortedBucket;
  }, [listOfEntityTypesEnabledForUser, exchanges]);

  const sortedAscEntitiesOfEnabledTypes = useMemo(() => {
    return sortAscEntitiesByLabel([...sortedEntitiesByEnabledType]);
  }, [sortedEntitiesByEnabledType]);

  return {
    listOfEntityTypesEnabledForUser,
    sortedEntitiesListOfEnabledTypes: {
      byTypeAsc: sortedEntitiesByEnabledType,
      asc: sortedAscEntitiesOfEnabledTypes,
    },
  };
};

export const useEntitiesListManager = () => {
  const { isAuthenticated } = useNewAuth();
  const { shouldDisplayAdminPanel } = usePermissionsManager();

  const {
    entitiesList,
    isLoading: isEntitiesListLoading,
    error: isEntitiesListError,
  } = useGetUniqueEntitiesList({ isAuthenticated });
  const { entitiesListDisplayedToAdmin } = useGetUniqueEntitiesListByAdmin({
    isAuthenticated,
    isAdmin: shouldDisplayAdminPanel,
  });
  const [exchanges, setExchanges] = useState<IExchange[]>([]);
  const [exchangesDisplayedToAdmin, setExchangesDisplayedToAdmin] = useState<IExchange[]>([]);

  // ________________ENTITIES FOR USER________________
  const entitiesManagedByTypes = useEntitiesByTypeManager(exchanges);

  const getExchangeLabelByDisplayName = useCallback(
    (displayName: string) => {
      if (exchanges.length === 0) return '';

      const requestedExchange = [...exchanges].find((exchange) => exchange.label === displayName);

      if (requestedExchange) {
        return requestedExchange.value;
      }

      console.error("Didn't find exchange by displayName: ", displayName);
      return '';
    },
    [exchanges],
  );

  const getDisplayNameByExchangeLabel = useCallback(
    (exchangeLabel: string) => {
      if (exchanges.length === 0) return '';

      const requestedExchange = [...exchanges].find((exchange) => exchange.value === exchangeLabel);

      if (requestedExchange) {
        return requestedExchange.label;
      }

      console.error("Didn't find exchange by value: ", exchangeLabel);
      return exchangeLabel;
    },
    [exchanges],
  );

  useEffect(() => {
    if (!entitiesList) return;

    const exchangesList = entitiesList.map(({ agioId, displayName, type, id }) => ({
      value: agioId,
      label: displayName,
      type: type,
      id,
    }));

    const sortedAscExchangesList = sortAscEntitiesByLabel(exchangesList);

    setExchanges(sortedAscExchangesList);
  }, [entitiesList]);

  // ________________ENTITIES FOR ADMIN________________

  useEffect(() => {
    if (!entitiesListDisplayedToAdmin) return;

    const exchangesListDisplayedToAdmin = entitiesListDisplayedToAdmin.map(({ agioId, displayName, type, id }) => ({
      value: agioId,
      label: displayName,
      type: type,
      id: id,
    }));

    setExchangesDisplayedToAdmin(exchangesListDisplayedToAdmin);
  }, [entitiesListDisplayedToAdmin]);

  return {
    exchanges,
    isEntitiesListLoading,
    isEntitiesListError,
    entitiesManagedByTypes,
    getExchangeLabelByDisplayName,
    getDisplayNameByExchangeLabel,
    exchangesDisplayedToAdmin,
  };
};

export const useEntitiesList = () => {
  const {
    exchanges,
    isEntitiesListLoading,
    isEntitiesListError,
    entitiesManagedByTypes,
    getExchangeLabelByDisplayName,
    getDisplayNameByExchangeLabel,
    exchangesDisplayedToAdmin,
  } = useContext(EntitiesListContext);

  assertIsDefined(exchanges, '"IEntitiesListContext.exchanges has to be defined!"');
  assertIsDefined(isEntitiesListLoading, '"IEntitiesListContext.isEntitiesListLoading has to be defined!"');
  assertIsDefined(entitiesManagedByTypes, '"IEntitiesListContext.entitiesManagedByTypes has to be defined!"');
  assertIsDefined(
    getExchangeLabelByDisplayName,
    '"IEntitiesListContext.getExchangeLabelByDisplayName has to be defined!"',
  );
  assertIsDefined(
    getDisplayNameByExchangeLabel,
    '"IEntitiesListContext.getDisplayNameByExchangeLabel has to be defined!"',
  );
  assertIsDefined(exchangesDisplayedToAdmin, '"IEntitiesListContext.exchangesDisplayedToAdmin has to be defined!"');

  return {
    exchanges,
    isEntitiesListLoading,
    isEntitiesListError,
    entitiesManagedByTypes,
    getExchangeLabelByDisplayName,
    getDisplayNameByExchangeLabel,
    exchangesDisplayedToAdmin,
  };
};
