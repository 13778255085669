import { TableBody } from '@mui/material';
import { useAnomalies } from '@/views/EntityMonitoringView/context/AnomaliesProvider.hooks';
import { AnomalyTableRow } from '@/views/EntityMonitoringView/components/AnomaliesTable/components/AnomalyTableRow/AnomalyTableRow';
import {
  StyledWrapper,
  StyledNoRows,
  StyledHeaderCell,
  StyledTable,
  StyledHeaderTableRow,
  StyledTableWrapper,
  StyledSectionTitle,
  StyledTableHead,
} from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable.styled';

export const AnomaliesTable = () => {
  const {
    uniqueSortedAnomalies,
    anomaliesTransactionDetailsVisibility,
    toggleAnomalyDetailsVisibility,
    anomaliesSectionRef,
    anomaliesTableContainerRef,
    anomaliesTableStickyHeaderRef,
  } = useAnomalies();

  if (uniqueSortedAnomalies.length === 0) {
    return (
      <StyledWrapper>
        <StyledSectionTitle>Anomalies</StyledSectionTitle>
        <StyledNoRows>No anomalies with extreme or high severities were found in the last 90 days.</StyledNoRows>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper ref={anomaliesSectionRef}>
      <StyledSectionTitle>Alert History</StyledSectionTitle>

      <StyledTableWrapper ref={anomaliesTableContainerRef}>
        <StyledTable aria-label="anomalies table">
          <StyledTableHead ref={anomaliesTableStickyHeaderRef}>
            <StyledHeaderTableRow>
              <StyledHeaderCell />
              <StyledHeaderCell>Alert Status</StyledHeaderCell>
              <StyledHeaderCell>Timestamp</StyledHeaderCell>
              <StyledHeaderCell>Variable</StyledHeaderCell>
              <StyledHeaderCell>Tag</StyledHeaderCell>
            </StyledHeaderTableRow>
          </StyledTableHead>
          <TableBody>
            {uniqueSortedAnomalies.map((row) => {
              const anomalyTransactionDetailsVisibility = anomaliesTransactionDetailsVisibility.find(
                (anomaly) => anomaly.id === row.id,
              );
              if (anomalyTransactionDetailsVisibility) {
                return (
                  <AnomalyTableRow
                    key={row.id}
                    row={row}
                    isAnomalyTransactionVisible={anomalyTransactionDetailsVisibility.isDisplayed}
                    toggleAnomalyDetailsVisibility={toggleAnomalyDetailsVisibility}
                  />
                );
              }
            })}
          </TableBody>
        </StyledTable>
      </StyledTableWrapper>
    </StyledWrapper>
  );
};
