import styled from '@emotion/styled';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { RatingsHeaderPermitted } from '@/components/molecules/Headers/RatingsHeaderPermitted';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const RatingsHeader = () => {
  const { shouldDisplayRisks } = usePermissionsManager();

  if (!shouldDisplayRisks) {
    return (
      <StyledHeader position="static">
        <Toolbar>
          <Typography variant="h4" style={{ flexGrow: 1 }} noWrap>
            Statistical Ratings
          </Typography>
        </Toolbar>
      </StyledHeader>
    );
  }

  return <RatingsHeaderPermitted />;
};

export default RatingsHeader;
