import ReactMarkdown from 'react-markdown';
import { useGetChangelog } from '@/api/changelog/changelog.hooks';
import Loader from '@/components/atoms/Loader/Loader';
import {
  StyledContainer,
  StyledLoaderContainer,
} from '@/views/DocumentationChangelogView/DocumentationChangelogView.styled';

export const DocumentationChangelogView = () => {
  const { isLoading, fetchedChangelog, error } = useGetChangelog();

  if (isLoading) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  if (error) {
    throw error;
  }

  return (
    <StyledContainer>
      <ReactMarkdown>{fetchedChangelog}</ReactMarkdown>
    </StyledContainer>
  );
};
