import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import AgioLogo from '@/assets/icons/sidebar-logo.svg';
import DocumentationIcon from '@/assets/icons/documentation.svg?react';
import CalculatorIcon from '@/assets/icons/calculator.svg?react';
import ChartIcon from '@/assets/icons/chart.svg?react';
import SettingsIcon from '@/assets/icons/settings.svg?react';
import LogoutIcon from '@/assets/icons/logout.svg?react';
import MonitoringIcon from '@/assets/icons/monitoring.svg?react';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { useTracking } from '@/hooks/useTracking';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { useGetDocumentation } from '@/api/documentation/documentation.hooks';
import { IconWithHover } from './components/IconWithHover/IconWithHover';
import { EAdminPaths, ERoutes } from '@/types/routes';
import {
  StyledContainer,
  StyledLogoWrapper,
  StyledTopNavBarWrapper,
  StyledAdminPanelIcon,
  StyledBottomNavWrapper,
  StyledSettingsPanelWrapper,
  StyledMenu,
  StyledMenuItem,
} from '@/layouts/MainLayout/components/SideBar/SideBar.styled';

const API_DOCS_HREF = 'https://api.agioratings.io/documentation/api';

const CHANGELOG_INTERNAL_HREF =
  'https://agioratings.atlassian.net/wiki/spaces/~71202009088119f52b447b834b88fc05eea440/pages/147849223/Changelog+Internal';

const SideBar = () => {
  const navigate = useNavigate();
  const { logOut } = useNewAuth();
  const { shouldDisplayAdminPanel } = usePermissionsManager();
  const { trackEvent } = useTracking();
  const { fetchDocumentation } = useGetDocumentation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [mouseOverButton, setMouseOverButton] = useState<boolean>(false);
  const [mouseOverMenu, setMouseOverMenu] = useState<boolean>(false);

  const handleDocumentationMenuClose = () => {
    setMouseOverButton(false);
    setMouseOverMenu(false);
  };

  const handleEnterDocumentationButton = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!mouseOverButton) {
      setMouseOverButton(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleLeaveDocumentationButton = () => {
    setTimeout(() => {
      setMouseOverButton(false);
    }, 1000);
  };

  const handleEnterDocumentationMenu = () => {
    if (!mouseOverMenu) {
      setMouseOverMenu(true);
    }
  };

  const handleLeaveDocumentationMenu = () => {
    setTimeout(() => {
      setMouseOverMenu(false);
    }, 100);
  };

  const handleWhitePaperClick = () => {
    handleDocumentationMenuClose();
    trackEvent('Whitepaper Clicked', {});
    fetchDocumentation(undefined, {
      onSuccess: ({ url }) => {
        window.open(url, '_blank');
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };
  const handleMenuOptionClick = (variant: 'changelog' | 'apiDocs' | 'changelogInternal') => {
    handleDocumentationMenuClose();
    if (variant === 'changelog') {
      navigate(ERoutes.DOCUMENTATION_CHANGELOG);
    }
    if (variant === 'apiDocs') {
      window.open(API_DOCS_HREF, '_blank');
    }

    if (variant === 'changelogInternal') {
      window.open(CHANGELOG_INTERNAL_HREF, '_blank');
    }
  };

  useEffect(() => setOpen(mouseOverButton || mouseOverMenu), [mouseOverButton, mouseOverMenu]);

  return (
    <StyledContainer>
      <StyledLogoWrapper>
        <Link href={`${ERoutes.MONITORING}/custom`} underline="none">
          <img src={AgioLogo} alt="Agio logo" />
        </Link>
      </StyledLogoWrapper>

      <StyledTopNavBarWrapper>
        <IconWithHover variant="link" href={ERoutes.RATINGS} Icon={ChartIcon} tooltip="Ratings" />
        <IconWithHover
          variant="link"
          href={`${ERoutes.MONITORING}/custom`}
          Icon={MonitoringIcon}
          tooltip="Monitoring"
        />
        <IconWithHover variant="link" href={ERoutes.RISK_SIMULATOR} Icon={CalculatorIcon} tooltip="Risk simulator" />

        <IconWithHover
          variant="menu"
          onMouseOver={handleEnterDocumentationButton}
          onMouseLeave={handleLeaveDocumentationButton}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          Icon={DocumentationIcon}
          tooltip="Documentation"
        />
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleDocumentationMenuClose}
          MenuListProps={{
            onMouseOver: handleEnterDocumentationMenu,
            onMouseLeave: handleLeaveDocumentationMenu,
            'aria-labelledby': 'basic-button',
          }}
        >
          <StyledMenuItem onClick={handleWhitePaperClick}>White Paper</StyledMenuItem>
          <StyledMenuItem onClick={() => handleMenuOptionClick('changelog')}>Changelog</StyledMenuItem>
          <StyledMenuItem onClick={() => handleMenuOptionClick('apiDocs')}>API Docs</StyledMenuItem>
          {shouldDisplayAdminPanel && (
            <StyledMenuItem onClick={() => handleMenuOptionClick('changelogInternal')}>
              Internal Changelog
            </StyledMenuItem>
          )}
        </StyledMenu>
      </StyledTopNavBarWrapper>

      <StyledBottomNavWrapper>
        <StyledSettingsPanelWrapper>
          <IconWithHover variant="link" href={ERoutes.SETTINGS} Icon={SettingsIcon} tooltip="Settings" />

          {shouldDisplayAdminPanel && (
            <Link href={`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}`}>
              <StyledAdminPanelIcon />
            </Link>
          )}
        </StyledSettingsPanelWrapper>

        <IconWithHover variant="button" onClick={logOut} Icon={LogoutIcon} tooltip="Logout" />
      </StyledBottomNavWrapper>
    </StyledContainer>
  );
};

export default SideBar;
