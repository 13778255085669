import Highcharts from 'highcharts/highstock';
import { isNumber, maxBy } from 'lodash';
import { adjustRiskZoneChartLabelForAlertStatusTrending } from '@/helpers/riskZoneMethods';
import {
  adjustSeverityLabelForAlertStatusTrending,
  adjustSeverityToNumberForAlertStatusTrending,
} from '@/helpers/severityLevelMethods';
import { formatLargeDollarValueWithSuffix } from '@/helpers/helpers';
import { ExtremesObject } from 'highcharts/highcharts.src';
import { IRiskZoneDTO, TRiskZone } from '@/types/riskZone';
import { AnomalyChartData, ChartData, ChartPoint, DataItemToMap } from '@/types/chartPoint';
import { IAnomalyShortForMonitoringDto } from '@/types/anomaly';
import { TSeverity } from '@/types/severityLevel';

export const syncChartsTickPositioner = function (this: { getExtremes: () => ExtremesObject }) {
  if (!this.getExtremes) return [];
  const extremes = this.getExtremes();
  if (extremes.min === undefined || extremes.max === undefined) return [];

  const positions: number[] = [];

  const shift = 5 * 3600 * 1000;

  const start =
    Date.UTC(
      new Date(extremes.min).getUTCFullYear(),
      new Date(extremes.min).getUTCMonth(),
      new Date(extremes.min).getUTCDate(),
      0,
      0,
      0,
      0,
    ) + shift;

  const end = extremes.max;

  const interval = Math.ceil((end - start) / 10 / (24 * 3600 * 1000)) * (24 * 3600 * 1000);

  let current = start;
  while (current <= end) {
    positions.push(current);
    current += interval;
  }

  return positions;
};

interface TooltipFormatterContextObject {
  x?: number;
  y: number;
  series: { name: string; chart: any; type: string };
  color: string;
  points: Highcharts.Point[];
}

export const alertStatusTooltipFormatter = function (this: TooltipFormatterContextObject): string {
  if (this.series.type === 'scatter') return `<span style="color:${this.color}">Click to see details</span>`;

  const customText =
    this.series.name === 'Alert Status'
      ? adjustRiskZoneChartLabelForAlertStatusTrending(this.y).toUpperCase()
      : adjustSeverityLabelForAlertStatusTrending(this.y).toUpperCase();

  return `<span style="color:${this.color}">●</span> <b>${this.series.name}</b>: ${customText}<br/>`;
};

export const flowsTooltipFormatter = function (this: TooltipFormatterContextObject) {
  const defaultFormat = this.series.chart.options.tooltip?.xDateFormat || '%A, %b %e, %I:%M %p';

  // Force correct UTC time in tooltip
  const date = this.series.chart.time.dateFormat(defaultFormat, this.x);

  const dateHtml = `<span style="font-size: 11px">${date}</span>`;

  const pointsHtmls = this.points.map((point) => {
    const value = isNumber(point.y) ? formatLargeDollarValueWithSuffix(point.y) : '';
    return `<span style="color:${point.color}">●</span><b>${point.series.name}</b>: ${value}<br />`;
  });

  const pointsHtml = pointsHtmls.join('');

  return `<div>${dateHtml}<br/>${pointsHtml}</div>`;
};

interface RiskZoneVariant {
  filterKey: 'riskZone';
  valueMapper: (item: TRiskZone) => number | null;
}

interface SeverityVariant {
  filterKey: 'netflow' | 'totalFlow';
  valueMapper: (item: TSeverity) => number | null;
}

interface CommonProps {
  data: Array<IRiskZoneDTO>;
  timestamps: Array<number>;
  filterKey: 'riskZone' | 'netflow' | 'totalFlow';
}

type TAlertStatusChartDataBasedOnSyncTimestampsMapperProps = CommonProps & (RiskZoneVariant | SeverityVariant);

export const mapAlertStatusChartDataBasedOnSyncTimestamps = ({
  data,
  timestamps,
  valueMapper,
  filterKey,
}: TAlertStatusChartDataBasedOnSyncTimestampsMapperProps): ChartData => {
  const mappedDataPoints: ChartPoint[] = data
    .filter((item) => (filterKey === 'riskZone' ? item.value : item[filterKey]))
    .map((item) => ({
      x: new Date(item.timestamp).getTime(),
      y: filterKey === 'riskZone' ? valueMapper(item.value) : valueMapper(item[filterKey]),
    }));

  return timestamps.map((timestamp) => {
    const foundValues = mappedDataPoints.filter((point) => point.x === timestamp);
    const dataUnavailableReplacementValue = filterKey === 'riskZone' ? valueMapper('no_risk') : valueMapper('none'); // treat Data Unavailable as min value

    if (!foundValues.length) {
      return {
        x: timestamp,
        y: dataUnavailableReplacementValue,
      };
    }

    if (foundValues.length === 1) {
      return foundValues[0];
    }

    const highestValue = maxBy(foundValues, (point) => point.y ?? -Infinity);

    return {
      x: timestamp,
      y: highestValue?.y || dataUnavailableReplacementValue,
    };
  });
};

// Risk zones should be sorted by date
export const mapAnomalyChartDataFromRiskZones = (
  riskZones: Array<IRiskZoneDTO>,
  anomalies: IAnomalyShortForMonitoringDto[],
): AnomalyChartData => {
  let mapped: AnomalyChartData = [];
  mapped = anomalies.map((anomaly) => {
    const extractedAnomalyDate = anomaly.timestamp.split('T')[0];
    const anomalyType = anomaly.metadata.type;
    const anomalySeverity = anomaly.severity;

    const foundRiskZone = riskZones.find((riskZone) => {
      const extractedRiskZoneDate = riskZone.timestamp.split('T')[0];

      if (extractedAnomalyDate === extractedRiskZoneDate) {
        if (anomalyType === 'netflow') {
          return riskZone.netflow === anomalySeverity;
        }

        if (anomalyType === 'totalflow') {
          return riskZone.totalFlow === anomalySeverity;
        }
      }
    });

    return {
      x: new Date(foundRiskZone?.timestamp || 0).getTime(),
      y: adjustSeverityToNumberForAlertStatusTrending(anomalySeverity),
      anomaly: anomaly,
    };
  });

  return mapped;
};

const generateFlowValue = (foundChartData: ChartPoint | undefined, isOutflow: boolean) => {
  if (foundChartData && foundChartData.y) {
    return isOutflow ? foundChartData.y * -1 : foundChartData?.y;
  }

  return 0; // replace missing data with 0 value
};

export const mapFlowsChartDataBasedOnSyncTimestamp = (
  data: Array<DataItemToMap>,
  timestamps: Array<number>,
  isOutflow = false,
): ChartData => {
  let mapped: ChartData = [];
  const mappedChartData = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: item.value,
      };
    });

  mapped = timestamps.map((timestamp) => {
    const foundChartData = mappedChartData.find((data) => data.x === timestamp);

    return {
      x: timestamp,
      y: generateFlowValue(foundChartData, isOutflow),
    };
  });

  return mapped;
};

const generateNetflowValue = (foundOutflow: ChartPoint, inflow: ChartPoint) => {
  if (isNumber(foundOutflow.y) && isNumber(inflow.y)) {
    return foundOutflow.y + inflow.y;
  }

  // all below should never happen as far as the inflows and outflows should include 0 for unavailable or nullable data
  if (isNumber(foundOutflow.y) && !isNumber(inflow.y)) {
    return foundOutflow.y;
  }

  if (!isNumber(foundOutflow.y) && isNumber(inflow.y)) {
    return inflow.y;
  }

  return 0;
};

export const mapNetflowsChainDataBasedOnSyncInflowsAndOutflows = (
  inflows: ChartData,
  outflows: ChartData,
): ChartData => {
  let mapped: ChartData = [];

  mapped = inflows.map((inflow) => {
    const currentTimestamp = inflow.x;
    const foundOutflow = outflows.find((outflow) => outflow.x === currentTimestamp);

    if (!foundOutflow) {
      return {
        x: currentTimestamp,
        y: 0,
      }; // should never happen, as far as the inflows and outflows are sync
    }

    return {
      x: currentTimestamp,
      y: generateNetflowValue(foundOutflow, inflow),
    };
  });

  return mapped;
};

export const balanceChartTickPositioner = function (this: {
  max: number;
  min: number;
  dataMin: number;
  dataMax: number;
}) {
  const positions: any = [];
  const divisions = 5;
  const tickInterval = (this.max - this.min) / divisions;

  for (let i = 0; i <= divisions; i++) {
    positions.push(this.min + i * tickInterval);
  }

  positions.info = {
    unitName: 'day',
    higherRanks: {},
    totalRange: positions[positions.length - 1] - positions[0],
  };
  return positions;
};

export const balancesTooltipFormatter = function (this: TooltipFormatterContextObject) {
  const defaultFormat = this.series.chart.options.tooltip?.xDateFormat || '%A, %b %e';

  // Force correct UTC time in tooltip
  const date = this.series.chart.time.dateFormat(defaultFormat, this.x);
  let tooltipText = `<span style="font-size: 11px">${date}</span><br/>`;

  // Area point
  this.points?.forEach((point) => {
    const value = point.y && Highcharts.numberFormat(point.y, 2);
    tooltipText += `<span style="color:${point.color}">●</span> ${point.series.name}: <b>${value}</b><br/>`;
  });

  return tooltipText;
};
