import { AppBar, Toolbar, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

interface IDocumentationHeaderProps {
  subtitle?: string;
}

const DocumentationHeader = ({ subtitle }: IDocumentationHeaderProps) => {
  return (
    <StyledHeader position="static">
      <Toolbar style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Typography variant="h4">Documentation</Typography>
        {subtitle && (
          <Typography color="white.70" variant="h4" fontSize="30px" fontStyle="italic" noWrap>
            {subtitle}
          </Typography>
        )}
      </Toolbar>
    </StyledHeader>
  );
};

export default DocumentationHeader;
