import { Box, Collapse, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDateToAlert } from '@/helpers/helpers';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { RiskZoneLabel } from '@/components/RiskZoneLabel/RiskZoneLabel';
import { AnomalyTransactionsTable } from '@/views/EntityMonitoringView/components/AnomaliesTable/components/AnomalyTableRow/AnomalyTransactionsTable';
import {
  StyledTableRow,
  StyledCell,
  StyledNestedTableCell,
} from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable.styled';
import { IAnomalyShortForMonitoringDto } from '@/types/anomaly';

interface IAnomalyTableRowProps {
  row: IAnomalyShortForMonitoringDto;
  isAnomalyTransactionVisible: boolean;
  toggleAnomalyDetailsVisibility: (anomalyId: string) => void;
}

export const AnomalyTableRow = ({
  row,
  isAnomalyTransactionVisible,
  toggleAnomalyDetailsVisibility,
}: IAnomalyTableRowProps) => {
  return (
    <>
      <StyledTableRow key={row.id} id={row.id}>
        <StyledCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => toggleAnomalyDetailsVisibility(row.id)}
            color="inherit"
          >
            {isAnomalyTransactionVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledCell>

        <StyledCell component="th" scope="row">
          <RiskZoneLabel riskZone={row.alertStatus} />
        </StyledCell>
        <StyledCell>{formatDateToAlert(row.timestamp)}</StyledCell>
        <StyledCell>{mapMetadataType(row.metadata?.type)}</StyledCell>
        <StyledCell>{row.alertStatusDescription}</StyledCell>
      </StyledTableRow>

      {/*expandable part*/}
      <StyledTableRow isHoverDeactivated>
        <StyledNestedTableCell colSpan={5}>
          <Collapse in={isAnomalyTransactionVisible} timeout="auto" unmountOnExit>
            {row.counterParties !== null && !!row.counterParties.length ? (
              <AnomalyTransactionsTable row={{ ...row, counterParties: row.counterParties }} />
            ) : (
              <Box sx={{ margin: 2 }}>
                <Typography color="white.100" fontSize="14px" gutterBottom>
                  There are no specific transactions driving this alert. This alert was triggered due to an decrease
                  movement in total activity for this entity.
                </Typography>
              </Box>
            )}
          </Collapse>
        </StyledNestedTableCell>
      </StyledTableRow>
    </>
  );
};
