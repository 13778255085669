import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, Typography } from '@mui/material';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { useMonitoringForm } from '@/views/MonitoringSettingsView/utils/useMonitoringForm';
import { EntitiesMonitoringForm } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/EntitiesMonitoringForm';
import { EntitiesEmailNotificationsForm } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/EntitiesEmailNotificationsForm';
import {
  StyledContainer,
  StyledForm,
  StyledButtonContainer,
  StyledButton,
} from '@/views/MonitoringSettingsView/MonitoringSettingsView.styled';

export const MonitoringSettingsView = () => {
  const [isDesktopScreen, setIsDesktopScreen] = useState(window.innerWidth >= 1200);
  const {
    monitoringMethods,
    handleMonitoringFormSubmit,
    isCreateMonitoringSettingsLoading,
    isGetMonitoringSettingsLoading,
    isEditUserEmailNotificationsSettingsLoading,
    isGetUserEmailNotificationsSettingsLoading,
  } = useMonitoringForm();
  const { entitiesManagedByTypes } = useEntitiesList();

  useEffect(() => {
    const handleResize = () => setIsDesktopScreen(window.innerWidth >= 1200);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledContainer>
      <FormProvider {...monitoringMethods}>
        <StyledForm onSubmit={handleMonitoringFormSubmit}>
          <EntitiesMonitoringForm
            listOfEntityTypesToDisplay={entitiesManagedByTypes.listOfEntityTypesEnabledForUser}
            sortedEntitiesByType={entitiesManagedByTypes.sortedEntitiesListOfEnabledTypes.byTypeAsc}
            formMethods={monitoringMethods}
            isGetMonitoringSettingsLoading={isGetMonitoringSettingsLoading}
          />

          <EntitiesEmailNotificationsForm
            sortedEntitiesByType={entitiesManagedByTypes.sortedEntitiesListOfEnabledTypes.byTypeAsc}
            formMethods={monitoringMethods}
            isGetUserEmailNotificationsSettingsLoading={isGetUserEmailNotificationsSettingsLoading}
          />

          <StyledButtonContainer>
            <StyledButton
              type="submit"
              variant="contained"
              fitContentWidth
              skipTypography={!isDesktopScreen}
              disabled={isCreateMonitoringSettingsLoading || isEditUserEmailNotificationsSettingsLoading}
              isDesktopScreen={isDesktopScreen}
            >
              {isDesktopScreen ? 'Save' : <BookmarkBorderRoundedIcon />}
            </StyledButton>
          </StyledButtonContainer>
        </StyledForm>
      </FormProvider>

      <Typography color="white.100" align="center" fontStyle="italic" m="10px auto">
        If you&apos;re interested in tracking something not on the current list, just{' '}
        <Link href="mailto:alerts@agioratings.io" underline="hover">
          send us
        </Link>{' '}
        a request, and we&apos;ll be happy to assist!
      </Typography>
    </StyledContainer>
  );
};
