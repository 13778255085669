import styled from '@emotion/styled';

export const StyledAlignedRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 100px;
`;

export const StyledIconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;
