import styled from '@emotion/styled';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const StyledDatePicker = styled(DatePicker)`
  background: #1b1b1e;

  & input.MuiInputBase-input.MuiOutlinedInput-input,
  button.MuiButtonBase-root.MuiIconButton-root {
    color: #ffffff8a;
  }
`;

export const StyledSmallContainer = styled.div`
  padding: 20px;
  border: 1px solid #2e2e31;
  width: 100%;
  border-radius: 4px;
  overflow: auto;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 50%;
`;

export const StyledTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  flex-basis: 50%;
`;

export const StyledSecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4em;
  color: #ffffff;
  opacity: 0.5;
`;

export const StyledVariableTitle = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
`;

export const StyledTableRow = styled.tr`
  height: 32px;

  & td {
    border-top: 1px solid #282829;
  }

  &:first-child td {
    border: none;
  }

  &:last-child td {
    border-top: 1px solid #ffffff;
  }
`;

export const StyledHeaderName = styled.th<{ entityCell?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  color: #f7f7f7;
  min-width: 120px;
  ${(props) => props.entityCell && 'color: #9388E8;'}
`;

export const StyledDateContainer = styled.div`
  width: 220px;
`;

export const StyledVariablesTable = styled.table`
  min-width: 500px;
`;

export const StyledTitleRow = styled.div`
  margin-top: 31px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
`;
