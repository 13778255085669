import styled from '@emotion/styled';
import InfoIcon from '@/assets/icons/info-bold.svg?react';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledIconWrapper = styled.div`
  padding-top: 3px;
  min-width: 20px;
  height: 20px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: #c4c4c4;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  gap: 5px;
`;
