import styled from '@emotion/styled';
import Highcharts from 'highcharts';
import HighchartsReact, { HighchartsReactProps } from 'highcharts-react-official';
import { format } from 'date-fns';
import { DISABLE_CHART_EXPORTING_MENU, RESET_ZOOM_BUTTON_POSITION } from '@/constants/chartOptions';
import { ChartData, ChartPoint } from '@/types/chartPoint';

const Figure = styled.figure`
  width: 100%;
  margin: 1em auto;
`;

interface TooltipFormatterContextObject {
  x: number;
  y: number;
  point: {
    series: {
      name: string;
    };
  };
}

interface TimeLineChartProps {
  alertId?: string | undefined;
  chartData: ChartData;
  anomalyChainData?: ChartPoint;
  options?: HighchartsReactProps;
}

const AnomalyTimeLineChart = ({ alertId, anomalyChainData, chartData, options = {} }: TimeLineChartProps) => {
  const backgroundColor = '#101014';
  const chartBackground = '#282829';

  chartData.sort((a, b) => a.x - b.x);

  const timestampOfLastDataPoint = chartData?.length ? chartData[chartData?.length - 1].x : null;
  const timestampOfFirstDataPoint = chartData?.length ? chartData[0].x : null;

  const matchedAnomalyData = chartData?.find((point) => point.x === anomalyChainData?.x);
  const anomalyData = matchedAnomalyData
    ? [
        {
          x: matchedAnomalyData.x,
          y: matchedAnomalyData.y,
          marker: {
            enabled: true,
            fillColor: '#E54545',
            radius: 5,
          },
        },
      ]
    : [];

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: backgroundColor,
      // height: 243,
      marginLeft: 120,
      zoomType: 'x',
      ...RESET_ZOOM_BUTTON_POSITION,
    },
    tooltip: {
      backgroundColor: '#1B1B1E',
      borderRadius: 8,
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        const date = format(this.x, 'dd MMM');
        const value = Highcharts.numberFormat(this.y, 2).replace(/ /g, ',');
        return `${date} <div style="display:inline-block; width:10px; height:10px; background-color:#5D51B8; border-radius:5px; margin:0 5px;"></div> ${value}`;
      },
    },
    title: {
      text: undefined,
    },
    subtitle: {
      text: undefined,
    },
    xAxis: {
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineColor: chartBackground,
      max: timestampOfLastDataPoint,
      min: timestampOfFirstDataPoint,
      type: 'datetime',
      tickLength: 0,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      tickPositioner: function (this: { max: number; min: number; dataMin: number; dataMax: number }) {
        const positions: any = [];
        const divisions = 5;
        const tickInterval = (this.max - this.min) / divisions;

        for (let i = 0; i <= divisions; i++) {
          positions.push(this.min + i * tickInterval);
        }

        positions.info = {
          unitName: 'day',
          higherRanks: {},
          totalRange: positions[positions.length - 1] - positions[0],
        };
        return positions;
      },
      labels: {
        style: {
          color: '#F7F7F7',
        },
      },
    },
    yAxis: {
      gridLineColor: chartBackground,
      title: {
        text: undefined,
      },
      labels: {
        formatter: function (this: { value: number }): string {
          if (this.value >= 1e9) {
            return '$' + this.value / 1e9 + 'B';
          }
          if (this.value >= 1e6) {
            return '$' + this.value / 1e6 + 'M';
          }

          if (this.value >= 1e3) {
            return '$' + this.value / 1e3 + 'k';
          }

          return '$' + this.value;
        },
        style: {
          color: '#F7F7F7',
          lineHeight: '15.809px',
        },
      },
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      layout: 'horizontal',
      itemMarginTop: 5,
      borderWidth: 0,
      symbolHeight: 10,
      symbolWidth: 10,
      useHTML: true,
      title: {
        text: '<div style="color:#F7F7F7; width: 180px; text-align: right;">Total Balance</div>',
        style: {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22.584px',
          color: '#F7F7F7',
        },
      },
      itemStyle: {
        color: '#828080',
        fontWeight: 'normal',
      },
      itemHoverStyle: {
        color: '#F7F7F7',
      },
    },
    plotOptions: {
      series: {
        pointPlacement: 'on',
      },
      area: {
        color: '#5D51B8',
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, 'rgba(93, 81, 184, 0.28)'],
            [0.8956, 'rgba(93, 81, 184, 0)'],
          ],
        },
        marker: {
          radius: 1,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
      line: {
        color: '#E54545',
        dashStyle: 'ShortDot',
      },
    },
    series: [
      {
        type: 'area',
        name: 'Total Balance',
        data: chartData,
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
      {
        type: 'scatter',
        name: 'Anomaly',
        data: anomalyData,
        marker: {
          symbol: 'circle',
          fillColor: '#E54545',
        },
      },
    ],
    time: {
      useUTC: false,
    },
    ...DISABLE_CHART_EXPORTING_MENU,
  };

  const mergedOptions = { ...chartOptions, ...options };

  return (
    <Figure>
      <HighchartsReact highcharts={Highcharts} options={mergedOptions} />
    </Figure>
  );
};

export default AnomalyTimeLineChart;
