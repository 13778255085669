import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import InfoBoldIcon from '@/assets/icons/info-bold.svg?react';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import {
  StyledAlignedRowWrapper,
  StyledIconWrapper,
} from '@/views/RiskSimulatorPortfolioView/components/SectionTitle/SectionTitle.styled';

interface ISectionTitleProps {
  title: string;
  tooltipDescription?: string | ReactNode;
}

export const SectionTitle = ({ title, tooltipDescription }: ISectionTitleProps) => {
  return (
    <StyledAlignedRowWrapper>
      <Typography color="white.100" fontSize="18px" fontWeight={600} lineHeight="150%" noWrap>
        {title}
      </Typography>

      {tooltipDescription && (
        <Tooltip arrow title={tooltipDescription}>
          <StyledIconWrapper>
            <InfoBoldIcon color="#FFFFFF" />
          </StyledIconWrapper>
        </Tooltip>
      )}
    </StyledAlignedRowWrapper>
  );
};
