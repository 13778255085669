import { useMemo } from 'react';
import { EventLogItem } from '@/views/AdminPanelView/components/Entities/AdminPanelEntityEventLog/components/EventLogItem/EventLogItem';
import { IEntityEventLog } from '@/types/eventLog';

interface ILogListProps {
  eventLogList: IEntityEventLog[];
}

export const LogList = ({ eventLogList }: ILogListProps) => {
  const sortedEventLogsDescByDate = useMemo(
    () =>
      [...eventLogList].sort((a, b) => {
        const timestampA = new Date(a.timestamp);
        const timestampB = new Date(b.timestamp);

        if (timestampA > timestampB) return -1;
        if (timestampA < timestampB) return 1;

        return 0;
      }),
    [eventLogList],
  );

  return (
    <>
      {sortedEventLogsDescByDate.map((eventLog) => (
        <EventLogItem key={eventLog.id} eventLog={eventLog} />
      ))}
    </>
  );
};
