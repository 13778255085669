import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import HighchartsBasic from 'highcharts';

import { useSyncChartsManager } from '@/views/EntityMonitoringView/components/SyncCharts/utils/useSyncChartsManager';
import { useBalancesChartManager } from '@/views/EntityMonitoringView/components/SyncCharts/utils/useBalancesChartManager';
import {
  StyledChartsWrapper,
  StyledChartWrapper,
  StyledScrollWrapper,
  StyledSpan,
} from '@/views/EntityMonitoringView/components/SyncCharts/SyncCharts.styled';
import { IRiskZoneDTO } from '@/types/riskZone';
import { IShortChainData } from '@/types/chainData';

export interface ISyncChartsProps {
  balances: IShortChainData[];
  riskZones: IRiskZoneDTO[];
  hourlyInflowData: IShortChainData[];
  hourlyOutflowData: IShortChainData[];
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
  },
});

export const SyncCharts = ({ balances, riskZones, hourlyInflowData, hourlyOutflowData }: ISyncChartsProps) => {
  const { areSyncTimestampsAvailable, refs, chartOptions } = useSyncChartsManager({
    riskZones,
    hourlyInflowData,
    hourlyOutflowData,
  });

  const { shouldRenderBalancesChart, balanceChartOptions } = useBalancesChartManager({ balances });

  return (
    <StyledChartsWrapper>
      <StyledSpan>Time series Charts</StyledSpan>
      <StyledScrollWrapper>
        <StyledChartWrapper>
          {areSyncTimestampsAvailable && (
            <>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType="stockChart"
                options={chartOptions.flowsChartOptions}
                ref={refs.flowsChartRef}
              />
              <HighchartsReact
                highcharts={Highcharts}
                constructorType="stockChart"
                options={chartOptions.alertStatusChartOptions}
                ref={refs.alertStatusChartRef}
              />
            </>
          )}
          {shouldRenderBalancesChart && <HighchartsReact highcharts={HighchartsBasic} options={balanceChartOptions} />}
        </StyledChartWrapper>
      </StyledScrollWrapper>
    </StyledChartsWrapper>
  );
};
