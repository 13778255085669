import { Typography } from '@mui/material';
import RedirectIcon from '@/assets/icons/redirect-icon.svg?react';
import { FONT_SIZE } from '@/views/EntityMonitoringView/components/EntityDetails/components/contstants';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { ParameterWithTimer } from '@/views/EntityMonitoringView/components/EntityDetails/components/ParameterWithTimer/ParameterWithTimer';
import { PdInfo } from '@/views/EntityMonitoringView/components/EntityDetails/components/DefaultRisks/PdInfo';
import {
  StyledColumnFlexWrapper,
  StyledLink,
  StyledSpacedBetweenRowWrapper,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { ERoutes } from '@/types/routes';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const RatedEntity = ({ displayName, pd }: Pick<IEntityDetailsProps, 'displayName' | 'pd'>) => {
  const { getExchangeLabelByDisplayName } = useEntitiesList();

  return (
    <>
      <StyledSpacedBetweenRowWrapper>
        <ParameterWithTimer parameter="Probability of Default (1y)" timestamp={pd?.lastUpdated ?? undefined} />
        <StyledColumnFlexWrapper>
          <PdInfo pd={pd} />
        </StyledColumnFlexWrapper>
      </StyledSpacedBetweenRowWrapper>

      {!!displayName && (
        <StyledLink href={`${ERoutes.RATINGS}/${getExchangeLabelByDisplayName(displayName)}`} underline="none">
          <Typography
            color="custom.grey.light"
            fontSize={FONT_SIZE}
            display="flex"
            alignItems="center"
            gap="3px"
            noWrap
          >
            Go to Statistical Rating <RedirectIcon />
          </Typography>
        </StyledLink>
      )}
    </>
  );
};
