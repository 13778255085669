import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import {
  IAdminEntityIdsApi,
  IEditEntityRequest,
  IGetEntitiesListRequest,
  IGetEntitiesListResponse,
} from '@/api/adminEntityIds/adminEntityIds.types';
import { IEntity, IEntityDto } from '@/types/entities';
import { IEntityEventLog } from '@/types/eventLog';

export class AdminEntityIdsApi implements IAdminEntityIdsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ADMIN_ENTITY_IDS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getEntitiesList({ page, size }: Partial<IGetEntitiesListRequest>) {
    const params = {
      page,
      size,
    };

    return this.httpClient.get<IGetEntitiesListResponse>(this.getBaseUrl(), { params });
  }

  getUniqueEntitiesList() {
    return this.httpClient.get<IEntity[]>(`${this.getBaseUrl()}alive`);
  }

  getEntityById(entityId: string) {
    return this.httpClient.get<IEntityDto>(`${this.getBaseUrl()}${entityId}/`);
  }

  getEntityEventLog(entityId: string) {
    return this.httpClient.get<IEntityEventLog[]>(`${this.getBaseUrl()}${entityId}/event_log`);
  }

  createEntity(payload: Partial<Omit<IEntityDto, 'id'>>) {
    const params = {
      ...payload,
    };
    return this.httpClient.post<Omit<IEditEntityRequest, 'id'>, IEntityDto>(this.getBaseUrl(), params);
  }

  editEntity({ id, ...payload }: IEditEntityRequest) {
    const params = {
      ...payload,
    };
    return this.httpClient.patch<Omit<IEditEntityRequest, 'id'>, IEntityDto>(`${this.getBaseUrl()}${id}/`, params);
  }
}
